.jideshare {
  box-sizing: border-box;
  z-index: 6;
  font-size: 0.875rem;
}

.jideshare-button-left {
  padding-top: 1px;
  margin-right: 8px;
}
.jideshare-button-right {
  padding-top: 2px;
  font-size: 15px;
}

.jideshare-modal {
  display: flex;
  position: absolute;
  bottom: 4rem;
  right: 0;
  min-width: 390px;
  width: 470px;
  max-width: 470px;
  border-radius: 10px;
  background-color: white;
  overflow-x: visible;
  overflow-y: auto;
  box-sizing: border-box;
  z-index: 5;
  border: 2px solid #c5eaff;
  box-shadow: 0px 8px 12px rgb(2 56 86 / 26%);
}
.jideshare-modal div {
  display: flex;
  box-sizing: border-box;
}

.jideshare-spinner {
  display: flex;
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

/***** username ******/

.jideshare-pick-username {
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.jideshare-pick-username .top h2 {
  color: #3c6176;
  font-size: 1.5rem;
}

.jideshare-pick-username .bottom {
  padding: 0.5rem 1rem;
  flex-direction: column;
}
.jideshare-pick-username .username-instructions {
  color: #3c6176;
  font-size: 0.875rem;
  font-weight: 600;
}
.jideshare-pick-username .username-instructions-subtext {
  color: #4c6e81;
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.jideshare-sp {
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.jideshare-sp-featured-section {
  padding: 0 0.5rem;
  color: #fff;
  background: #007cc9;
  font-weight: 600;
}

.jideshare-sp-featured-section a {
  color: #fff;
}

.jideshare-sp-share-link {
  width: 100%;
  flex-direction: column;
  padding: 10px 15px 15px;
  background: #115289;
  color: white;
  border: 0;
}
.jideshare-sp-share-link .jideshare-large-text {
  color: white;
  font-size: 0.875rem;
  margin: 0.25rem 0 0.75rem;
  font-weight: 600;
  line-height: 170%;
}
.jideshare-sp-makepublic {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0;
}

.jideshare-sp-upper {
  width: 100%;
  flex-direction: column;
}
.username__container {
  margin-bottom: 0.5rem;
  padding: 0.9rem;
  border-bottom: 2px solid #dff1fb;
  flex-direction: column;
}
.username__container .top {
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
}
.username__container .top h2.username-header,
.username__container .top .username-group {
  display: flex;
  flex-direction: column;
  color: #3c6176;
  flex-basis: 100%;
  margin: 0;
  margin-left: auto;
  margin-right: 1rem;
  font-size: 1.125rem;
  letter-spacing: 0;
  white-space: nowrap;
  transition: transform 0.2s;
}
.username-group:hover {
  transform: scale(1.025);
}
.username__container .top .username-group span.username {
  margin-left: auto;
  font-size: 0.75rem;
}
.username__container .top .username-group span.profile-link {
  margin-left: auto;
  font-size: 0.75rem;
}

.jideshare-sp-upper .project-name__container {
  padding: 0rem 0.9rem 0.9rem;
  flex-direction: column;
  border-bottom: 2px solid #dff1fb;
  margin-bottom: 8px;
}
.jideshare-sp-upper p,
.jideshare-sp-upper .project-name__container p {
  margin: 0;
  font-weight: 600;
}
input.jideshare-input-text-box {
  border-width: 2px;
  border-radius: 8px;
  margin: 0;
}
input.jideshare-input-text-box:focus {
  background: #fbfdff;
  border: 2px solid #00a5ff;
  box-shadow: inset 0px 4.28837px 14.5805px rgba(41, 95, 124, 0.05);
}

/* Components */
.jideshare-header {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
}

.jideshare-text {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: rgba(15, 58, 82, 0.7);
  margin-bottom: 2px;
}
.jideshare-medium-text {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #4a6471;
  margin-bottom: 3px;
}
.jideshare-large-text {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
  color: black;
  margin-bottom: 3px;
}
.jideshare-sp-lower .jideshare-large-text {
  margin: 0px 0 10px;
}
.jideshare-sp-makepublic .jideshare-large-text {
  margin: 0;
  color: #3c6176;
  font-size: 0.75rem;
}
.jideshare-sp-upper > .jideshare-large-text {
  margin-bottom: 8px;
}

.jideshare-space {
  margin-top: 10px;
  padding-top: 10px;
  height: 0;
}

.jideshare-input-text-box-wrapper {
  display: flex;
  flex-direction: column;
}
.jideshare-input-text-box-errormsg {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  color: red;
  margin-bottom: 5px;
}
.jideshare-input-text-box {
  font-family: "Open Sans", sans-serif;
  border: 1px solid rgba(0, 166, 255, 0.3);
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  color: #4a6471;
}
input.jideshare-input-text-box {
  padding: 8px 12px;
  font-size: 14px;
  color: #4a6471;
}
.jideshare-input-text-box:focus {
  border: 1px solid #00a5ff;
  box-shadow: 0px 0px 18px 0px rgba(0, 166, 255, 0.15) inset;
}

.jideshare-toggle {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 60px;
  height: 30px;
  padding-left: 15px;
}
.jideshare-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.jideshare-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.jideshare-slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .jideshare-slider {
  background-color: #50e08a;
}
input:focus + .jideshare-slider {
  box-shadow: 0 0 1px #50e08a;
}
input:checked + .jideshare-slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}
.jideshare-slider.jideshare-round {
  border-radius: 30px;
}
.jideshare-slider.jideshare-round:before {
  border-radius: 50%;
}

.jide-submit__container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  margin: 0;
}

.jide-submit__container #unpublish {
  background: transparent;
  color: #3c6176;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "Open Sans";
  font-size: 0.8rem;
  margin: 0 0 0.5rem;
  letter-spacing: 0;
}
.jide-submit__container #unpublish:hover {
  box-shadow: none;
  opacity: 0.7;
}

.jideshare .jideshare-submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.06rem;
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 0.75rem;
  background-color: #00a5ff;
  color: white;
}
.jideshare-submit-button span {
  margin: 0 auto;
  font-size: 0.875rem;
  padding: 0 12px;
  width: 100%;
  text-align: center;
  font-weight: bold;
}
.jideshare-submit-button.jideshare-unsaved:hover {
  cursor: pointer;
  background-color: #2fb6ff;
}
.jideshare-submit-button.jideshare-saved {
  opacity: 0.5;
}

.jideshare-link-button {
  width: 100%;
  font-size: 0.875rem;
}
.jideshare-link {
  flex-direction: row;
  width: 100%;
  overflow: hidden;
}
.jideshare-link a {
  text-decoration: none;
}
.jideshare-link-left {
  border-radius: 10px;
  background: rgba(251, 253, 255, 0.35);
  border: 2px solid #00a5ff;
  box-shadow: inset 0px 4.28837px 14.5805px rgba(41, 95, 124, 0.05);
  flex-basis: 50%;
  display: flex;
  color: white;
  align-items: center;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  padding: 10px 0px 10px 12px;
  margin: 0;
  text-overflow: ellipsis;
}
.jideshare-link-right {
  display: flex;
  justify-content: space-evenly;
}
.jideshare-link-right .jideshare__button {
  display: flex;
  align-items: center;
  width: 50%;
  margin: 0 auto 0 0.75rem;
  cursor: pointer;
  background: #00a5ff;
  border: 2px solid #5ac5ff;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 700;
  color: white;
  text-transform: capitalize;
  font-family: "Open Sans";
  padding: 0 0.25rem 0 1rem;
  letter-spacing: 0.05em;
}

.jideshare-link-right .jideshare__button span {
  justify-self: center;
  display: flex;
  align-self: center;
  margin-right: 1rem;
}

.jideshare__icons {
  color: white;
  background: #3fbbff;
  border-radius: 6px;
  padding: 0.8rem;
  display: flex;
  margin-left: auto;
  align-self: flex-end;
  width: 0.875rem;
  height: 0.875rem;
}

.jideshare__icons path {
  stroke: white;
}
.jideshare__icons svg {
  fill: white;
}

.jideshare-modal div .buttons__container > :first-child {
  margin-right: 0.5rem !important;
}
.jideshare-modal div .buttons__container > :last-child {
  margin-left: 0.5rem;
}
.jideshare-modal div .buttons__container > * {
  margin: 0;
}

@media only screen and (max-width: 650px) {
  .jideshare-modal {
    width: 90vw;
    right: 0;
  }
}
@media only screen and (max-width: 390px) {
  .jideshare-modal {
    max-width: 100%;
    min-width: 90vw;
  }
}

.jideshare-rec-container {
  padding: 0rem 0.9rem 0.9rem;
  flex-direction: column;
  border-bottom: 2px solid #dff1fb;
  width: 100%;
}
.jideshare-rec-container > p {
  font-weight: 600;
  margin-bottom: 5px !important;
}
.jideshare-rec {
  height: 150px;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.jideshare-rec .jrs-modal-preview {
  height: 100%;
  width: 150px;
  margin-right: 10px;
}
.jideshare-rec .jrs-modal-preview .jrs-modal-preview-upper {
  height: 110px;
}
.jideshare-rec .jideshare-rec-new {
  width: 80px !important;
  color: #b5e3fd;
}
.jideshare-rec .jideshare-rec-new:hover {
  background-color: #e8f6fd;
  color: #67c9ff;
}
.jideshare-rec-new.jrs-modal-preview {
  border: 2px solid #27b2ff;
  color: #27b2ff;
  background: #edf9ff;
}
.jideshare-rec .jideshare-rec-new-upper {
  flex-grow: 1;
  align-items: flex-end;
  padding-bottom: 10px;
  justify-content: center;
  text-align: center;
  font-size: 20px;
}
.jideshare-rec .jideshare-rec-new-lower {
  flex-grow: 1;
  padding-top: 10px;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.05em;
}
.jideshare-rec-initial {
  height: 50px;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.jideshare-rec-initial .jideshare-rec-new {
  width: 140px !important;
  color: #b5e3fd;
  flex-direction: row !important;
}
.jideshare-rec-initial .jideshare-rec-new:hover {
  background-color: #e8f6fd;
  color: #67c9ff;
}
.jideshare-rec-initial .jideshare-rec-new-upper {
  flex-shrink: 0;
  width: 25px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  margin-left: 10px;
}
.jideshare-rec-initial .jideshare-rec-new-lower {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  margin-right: 10px;
  color: #27b2ff;
  font-weight: 800;
  letter-spacing: 0;
  padding: 0 1px;
}
