.payment-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: 'Gotham', sans-serif;
}

.payment-modal.hidden {
  display: none;
}

.payment-modal-inner {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  box-shadow: 0px 5px 15px 0px rgba(146, 161, 173, 0.2);
}

.payment-modal-inner > .form-container {
  margin: 0;
  border: none;
  padding: 36px;
}
