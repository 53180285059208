.jide-video-player {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  border-radius: 10px;
}
.jide-video-player .header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsl(201, 100%, 21%);
  padding: 9px;
  cursor: move;
}
.jide-video-player .video-title {
  text-align: center;
  color: #fff;
  margin: 0 auto;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jide-video-player .header .buttons {
  display: flex;
  align-items: center;
}
.jide-video-player .header .buttons.left button {
  margin-right: 1rem;
}
.jide-video-player .header .buttons.right button {
  margin-left: 1rem;
}
.jide-video-player .header button {
  box-shadow: none;
  padding: 0;
  border-radius: 4px;
  background: hsl(201, 66%, 29%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  transition: all 0.2s;
}
.jide-video-player .header button:hover {
  transform: scale(1.1) perspective(1px) translateZ(0);
  backface-visibility: hidden;
}
.jide-video-player .header .icon {
  height: 8px;
  width: 8px;
  padding: 0;
}
.jide-video-player .header button.go-back-btn .icon {
  height: 10px;
  width: 10px;
}
.jide-video-player .header button path {
  transition: all 0.2s;
}
.jide-video-player .header button.go-back-btn path {
  fill: rgba(255, 255, 255, 0.7);
}
.jide-video-player .header button.go-back-btn:hover path {
  fill: rgba(255, 255, 255, 1);
}
.jide-video-player .header button.exit-cur-video-player path {
  stroke: rgba(255, 255, 255, 0.7);
}
.jide-video-player .header button.exit-cur-video-player:hover path {
  stroke: rgba(255, 255, 255, 1);
}
.jide-video-player .video-container {
  background: hsl(201, 100%, 14%);
}
