.ui-accordion .ui-accordion-title {
  cursor: pointer;
  font-size: 1.2em;
}

.ui-accordion .ui-accordion-title.ui-active {
  font-weight: bold;
}

.ui-accordion .ui-accordion-title.ui-disabled {
  color: #aaa;
  cursor: inherit;
}

.ui-accordion .ui-accordion-section {
  border-top: 1px solid #ccc;
  padding-top: 1rem;
  margin-top: 1rem;
}

.ui-accordion .ui-accordion-section:first-of-type {
  border-top: none;
}
