.ui-text-area {
  background: #fbfdff;
  border: 1px solid hsl(201, 70%, 50%);
  border-radius: 0.5rem;
  box-shadow: inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
  font-family: inherit;
  font-size: 1rem;
  line-height: 1;
  box-sizing: border-box;
  padding: 1rem;
  height: 5rem;
  width: 100%;
  resize: vertical;
}

.ui-text-area:focus {
  border: 2px solid #00a5ff;
  padding-left: calc(1rem - 1px);
  box-shadow: 0px 0.25rem 1rem rgba(0, 165, 255, 0.2),
    inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
}

.ui-text-area:disabled {
  background: #e7eaec;
  border: 2px solid #adc2ce;
  color: #b7c0c7;
}
