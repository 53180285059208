.auth-form-signup td.email-value > div {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
}
.auth-form-signup td.email-value > div span {
  max-width: 100%;
  word-wrap: break-word;
}
.auth-form-signup td.email-value .spinner-v2-wrapper {
  justify-content: flex-end;
}
.auth-form-signup td.email-value .spinner-v2 {
  margin: 0;
  margin-right: 2px;
}
/* coppa direct notice */
.auth-form-signup .coppa-direct-notice p {
  color: inherit;
  line-height: inherit;
}
.auth-form-signup .scrollable-direct-notice-container.outer {
  height: 100px;
  border: 1px solid hsl(201, 47%, 90%);
  overflow: hidden;
  box-sizing: border-box;
  padding: 4px;
}
.auth-form-signup .scrollable-direct-notice-container.inner {
  overflow-y: scroll;
  height: 100%;
  max-height: 100%;
  padding: 3px 6px;
  box-sizing: border-box;
}
.auth-form-signup .scrollable-direct-notice-container.inner::-webkit-scrollbar {
  background: hsl(201, 54%, 94%);
  width: 8px;
  border-radius: 6px;
}
.auth-form-signup
  .scrollable-direct-notice-container.inner::-webkit-scrollbar-thumb {
  background: hsl(201, 30%, 75%);
  width: 8px;
  border-radius: 6px;
}
.auth-form-signup .scrollable-direct-notice-container .coppa-direct-notice {
  font-size: 11px;
  line-height: 1.8em;
  color: rgb(79, 87, 95);
}

.auth-form-signup p.checkbox-label {
  font-size: 14px;
  width: 100%;
  margin-top: 1.2em;
}

@media only screen and (min-width: 900px) {
  .auth-form-signup p.checkbox-label {
    margin-top: initial;
  }
}
