#jide-nb {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color:#292563;
  height: 60px;
}

#jide-nb div {
  display: flex;
  box-sizing: border-box;
}

#jide-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: rgba(255, 255, 255, 1);
  width: 60px;
}
#jide-loading-spinner svg {
  height: 60%;
  width: 60%;
}
