.learner-class-scheduler.card-v3.padded {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-shadow: 0px 20px 30px -10px #1f395029;
  padding: 1.5rem 2rem;
}
.learner-class-scheduler h1 {
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  color: hsl(208, 100%, 11%);
  line-height: 30px;
  font-weight: 900;
  margin-bottom: 1rem;
  font-size: 19px;
  letter-spacing: 0;
}
.learner-class-scheduler h1.centered {
  text-align: center;
}

.learner-class-scheduler h3 {
  font-family: 'Open San', sans-serif;
  color: hsl(208, 100%, 11%);
  font-size: 14px;
  font-weight: 700;
  text-transform: initial;
  letter-spacing: 0px;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.learner-class-scheduler hr {
  border: none;
  border-bottom: 1px solid hsl(208, 36%, 87%);
  margin: 0;
}

.learner-class-scheduler .header-and-availability-controls {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0.7rem;
  margin-top: 1.5rem;
}

.learner-class-scheduler .header-and-availability-controls h3 {
  margin: 0.5rem 1rem 0.5rem 0;
}

.learner-class-scheduler .availability-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.learner-class-scheduler .no-availability {
  text-align: center;
  padding: 30px 0;
}

.learner-class-scheduler .availability-controls button {
  box-shadow: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  color: hsl(208, 100%, 53%);
  border: 1px solid hsl(208, 100%, 53%);
  border-radius: 5px;
  background: hsla(208, 100%, 53%, 0.05);
}

.learner-class-scheduler .availability-controls button:disabled {
  opacity: 0;
}
.learner-class-scheduler .availability-controls button:nth-of-type(1) {
  margin-right: 1rem;
}

.learner-class-scheduler table th {
  padding-bottom: 10px;
}

.learner-class-scheduler table th span.header-date {
  font-weight: 300;
}

.learner-class-scheduler .confirmation-message {
  text-align: center;
  margin: 16px auto;
  line-height: 30px;
}

.learner-class-scheduler .confirmation-message.extra-margin {
  text-align: center;
  margin: 48px auto;
  line-height: 30px;
}

.learner-class-scheduler .action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 21px;
}
.learner-class-scheduler .action-buttons.sm-icon {
  justify-content: center;
}
.learner-class-scheduler .action-buttons button .icon {
  height: 14px;
  width: 14px;
  padding: 6px;
}
.learner-class-scheduler .action-buttons.sm-icon button .icon {
  height: 12px;
  width: 12px;
  padding: 8px;
}
.learner-class-scheduler .action-buttons button:first-child .icon {
  background: hsl(208, 100%, 92%);
}
.learner-class-scheduler .action-buttons button:first-child .icon path {
  fill: hsl(208, 30%, 50%);
}

.learner-class-scheduler .action-buttons button:last-child .icon {
  background: hsl(208, 100%, 62%);
}
.learner-class-scheduler .action-buttons button:last-child .icon path {
  fill: #fff;
}

.learner-class-scheduler .availability-wrapper {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  overflow-y: visible;
  flex-direction: column;
  flex: 1;
  padding-bottom: 10px;
}

.learner-class-scheduler .availability-table-wrapper {
  flex: 1;
  box-sizing: border-box;
}

.learner-class-scheduler .availability-table {
  width: 600px;
}

.learner-class-scheduler .availability-row {
  display: flex;
  flex-direction: row;
}

.learner-class-scheduler .availability-table button.class-selector {
  box-shadow: none;
  border: 1px solid hsl(208, 100%, 62%);
  border-radius: 8px;
  padding: 5px 8px;
  width: 75%;
  text-transform: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: hsl(208, 100%, 53%);
  background: hsla(208, 100%, 53%, 0.03);
  font-weight: 600;
}

.learner-class-scheduler .availability-item {
  width: 120px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 0;
}

.learner-class-scheduler .availability-item.unavailable button,
.learner-class-scheduler .availability-item button:disabled {
  text-decoration: line-through;
  border: none;
  color: #b2bbc3;
  font-weight: 100;
  background: transparent;
}

.learner-class-scheduler .availability-date-headers {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background: #fff;
  margin: 0 0 1rem;
}

.learner-class-scheduler .availability-date-headers > div {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  min-width: 120px;
  white-space: nowrap;
  margin-bottom: 6px;
  font-weight: 400;
}

.learner-class-scheduler .availability-date-headers > div span {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  white-space: nowrap;
  font-style: italic;
  opacity: 0.5;
  font-size: 0.6875rem;
  margin-top: 0.25rem;
}

.learner-class-scheduler .availability-container {
  overflow-y: scroll;
  flex: 1;
  padding: 10px;
}

.learner-class-scheduler table {
  border-collapse: collapse;
  width: 100%;
}

.learner-class-scheduler table td,
.learner-class-scheduler table th {
  padding: 5px 24px;
}

.learner-class-scheduler .header-table-wrapper {
  padding: 0 10px;
}
.learner-class-scheduler table th div.table-header {
  padding: 5px 8px;
}

.learner-class-scheduler table td,
.learner-class-scheduler table td > *,
.learner-class-scheduler table th,
.learner-class-scheduler table th > * {
  vertical-align: middle;
  text-align: center;
}

.learner-class-scheduler table td > div {
  border: 1px solid hsl(208, 100%, 53%);
  border-radius: 8px;
  padding: 5px 8px;
}
.learner-class-scheduler table td.unavailable div {
  text-decoration: line-through;
  border: none;
}

.learner-class-scheduler table button.class-selector {
  background: transparent;
  box-shadow: none;
  color: hsl(208, 14%, 36%);
  border: 1px solid hsl(208, 100%, 62%);
  border-radius: 8px;
  padding: 5px 8px;
  width: 100%;
  text-transform: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.learner-class-scheduler table button.class-selector:disabled {
  text-decoration: line-through;
  border: none;
  color: hsl(208, 14%, 60%);
}
@media only screen and (min-width: 400px) {
  .learner-class-scheduler .header-and-availability-controls {
    flex-direction: row;
    align-items: center;
  }
  .learner-class-scheduler .availability-controls {
    margin-left: auto;
  }
}
@media only screen and (min-width: 830px) {
  .learner-class-scheduler .availability-item {
    width: 140px;
    min-width: 140px;
  }
  .learner-class-scheduler .availability-date-headers > div {
    width: 140px;
    min-width: 140px;
  }
  .learner-class-scheduler .availability-table button.class-selector {
    width: 65%;
  }
}
