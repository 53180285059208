.ui-table {
  margin-top: 1rem;
  border: 1px solid #d4ebf2;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 0.5rem;
  text-align: left;
  width: 100%;
  background-color: #fff;
}

.ui-table.ui-fixed {
  table-layout: fixed;
}

.ui-table > thead > tr:first-child th:first-child {
  border-top-left-radius: 0.5rem;
}

.ui-table > thead > tr:first-child th:last-child {
  border-top-right-radius: 0.5rem;
}

.ui-table > tbody > tr:last-child td:first-child {
  border-bottom-left-radius: 0.5rem;
}

.ui-table > tbody > tr:last-child td:last-child {
  border-bottom-right-radius: 0.5rem;
}

.ui-table th:first-child {
  border-left: none;
}

.ui-table.ui-striped tbody tr:nth-child(2n + 1) {
  background-color: #ebf8ff;
}

.ui-table.ui-striped tbody tr:nth-child(2n) {
  background-color: #f6fcff;
}

.ui-table th {
  border-left: 1px solid #d4ebf2;
  padding: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: #5c8095;
}

.ui-table td:first-child {
  border-left: none;
}

.ui-table td {
  border-left: 1px solid #d4ebf2;
  border-top: 1px solid #d4ebf2;
  padding: 1rem;
}
