.jide-math-env {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: stretch;
  overflow-y: hidden;
  background-color: white;
}

.jide-math-env .left-panel {
  flex: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  border-right: 1px solid hsl(208, 36%, 87%);
}

.jide-math-env .right-panel {
  flex: 1;
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: hidden;
}

.jide-math-env .right-panel-header {
  margin: 0 14px;
  border-bottom: 1px solid hsl(208, 36%, 87%);
  box-sizing: border-box;
  max-width: 100%;
}

.jide-math-env .right-panel-header > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 0;
  min-height: 56px;
}

.jide-math-env .header-tag-v2 {
  color: hsl(201, 100%, 43%);
  background: hsla(201, 100%, 43%, 0.1);
  margin: 0 10px;
}

.jide-math-env .math-problem-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-family: 'Open Sans', sans-serif;
  color: hsl(207, 20%, 46%);
  font-weight: bold;
}

.jide-math-env .math-problem-nav button.whiteboard-control .icon {
  height: 8px;
  width: 8px;
}

.jide-math-env .math-problem-nav button.whiteboard-control:last-child {
  margin-right: 0;
  margin-left: 12px;
}

.jide-math-env .right-panel-header h3.right-panel-title {
  margin: 0;
  font-size: 18px;
  font-family: 'Gotham', sans-serif;
}
