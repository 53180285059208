/* TREE STYLING */

.ui-tree .ui-tree-subtree {
  margin: 0;
  padding: 0;
}

.ui-tree .ui-tree-subtree > li {
  list-style-type: none;
  display: flex;
  align-items: stretch;
}

.ui-tree .ui-tree-lines {
  display: flex;
  flex-direction: column;
}

.ui-tree .ui-tree-lines::before,
.ui-tree .ui-tree-lines::after {
  content: '';
  display: block;
  margin-left: 6px;

  border-left: 1px solid #afcfda;
}

.ui-tree .ui-tree-lines::before {
  border-bottom: 1px solid #afcfda;
  height: 7px;
  width: 16px;
}

.ui-tree .ui-tree-lines::after {
  flex-grow: 1;
}

.ui-tree li:last-of-type > .ui-tree-lines::after {
  display: none;
}

/* TREE NODE STYLING */
.ui-tree .ui-tree-node {
  display: flex;
  flex-direction: column;
}

.ui-tree .ui-tree-label {
  display: flex;
}

.ui-tree .ui-tree-bullet-container {
  display: flex;
  flex-direction: column;
}

.ui-tree .ui-tree-bullet {
  height: 14px;
  width: 14px;
  border: 1px solid #afcfda;
  box-sizing: border-box;
  border-radius: 1px;

  display: flex;
  align-items: center;

  cursor: pointer;
}

.ui-tree .ui-tree-bullet.ui-opened {
  background: #3f6876;
}

.ui-tree .ui-tree-bullet svg {
  display: block;
  margin: 0 auto;
}

.ui-tree .ui-tree-bullet.ui-collapsed path {
  fill: #3f6876;
}

.ui-tree .ui-tree-bullet.ui-opened path {
  fill: #fff;
}

.ui-tree .ui-tree-bullet-container::after {
  flex-grow: 1;

  content: '';
  display: block;
  width: 7px;
  box-sizing: border-box;
  border-right: 1px solid #afcfda;
}

.ui-tree .ui-tree-node-end .ui-tree-bullet-container::after {
  display: none;
}

.ui-tree .ui-tree-node-link {
  margin-left: 20px;
  margin-bottom: 20px;
}

.ui-tree .ui-tree-node-link a {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.12em;
  color: #245364;
  text-decoration: none;
  vertical-align: top;
}

.ui-tree .ui-tree-node-link a.ui-tree-active {
  font-weight: bold;
}
