.spinner-v2-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.spinner-v2 {
  margin: 8px;
  position: relative;
  width: 80px;
  height: 80px;
}
button .spinner-v2,
.button .spinner-v2,
.submit-button .spinner-v2 {
  width: 24px;
  height: 24px;
}
.spinner-v2 .spinner-dot-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
.spinner-v2 .spinner-dot-container > div:first-child {
  display: flex;
  flex: 15;
}
.spinner-v2 .spinner-dot-container > div:last-child {
  flex: 85;
}
.spinner-v2 .spinner-dot-container .spinner-dot {
  background-color: #ccc;
  display: block;
  flex: 30;
  border-radius: 50%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.spinner-v2 .spinner-dot-container .spinner-dot-left,
.spinner-v2 .spinner-dot-container .spinner-dot-right {
  flex: 85;
}
.spinner-v2 .spinner-dot-container-1 {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
.spinner-v2 .spinner-dot-container-2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.spinner-v2 .spinner-dot-container-3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.spinner-v2 .spinner-dot-container-4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.spinner-v2 .spinner-dot-container-5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.spinner-v2 .spinner-dot-container-6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.spinner-v2 .spinner-dot-container-7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.spinner-v2 .spinner-dot-container-8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.spinner-v2 .spinner-dot-container-9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.spinner-v2 .spinner-dot-container-10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.spinner-v2 .spinner-dot-container-11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.spinner-v2 .spinner-dot-container-12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.spinner-v2 .spinner-dot-container-1 .spinner-dot {
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}
.spinner-v2 .spinner-dot-container-2 .spinner-dot {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner-v2 .spinner-dot-container-3 .spinner-dot {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner-v2 .spinner-dot-container-4 .spinner-dot {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner-v2 .spinner-dot-container-5 .spinner-dot {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.spinner-v2 .spinner-dot-container-6 .spinner-dot {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.spinner-v2 .spinner-dot-container-7 .spinner-dot {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.spinner-v2 .spinner-dot-container-8 .spinner-dot {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.spinner-v2 .spinner-dot-container-9 .spinner-dot {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.spinner-v2 .spinner-dot-container-10 .spinner-dot {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.spinner-v2 .spinner-dot-container-11 .spinner-dot {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.spinner-v2 .spinner-dot-container-12 .spinner-dot {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
