.jide-videos-widget.left-corner {
  left: 16px;
  right: unset;
}
.jide-videos-widget .video-container {
  position: relative;
  padding-top: 56.25%;
}
.jide-videos-widget .video-spinner-container {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.jide-videos-widget .video-container .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.jide-videos-widget .video-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

/********** Styles for Video Selector **********/

.jide-videos-widget .video-selector {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: hsl(201, 100%, 96%);
  border-radius: 35px;
  box-shadow: 0 10px 34px -10px #5e8db469;
  border: 2px solid #e3f2ff;
}
.jide-videos-widget .video-selector .header {
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ddefff;
  min-height: 54px;
}
.jide-videos-widget .video-selector .header .module-name {
  color: hsl(202, 94%, 19%);
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  max-width: calc(100% - 108px);
  line-height: 20px;
}
.jide-videos-widget .video-selector .header .module-name.no-videos {
  max-width: 200px;
}
.jide-videos-widget .close-video-select-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
}
.jide-videos-widget button.close-video-select {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  line-height: 0;
  transition: transform 0.2s;
  background: initial;
  border: none;
  box-shadow: initial;
}
.jide-videos-widget button.close-video-select:hover {
  transform: scale(1.05) perspective(1px) translateZ(0);
  backface-visibility: hidden;
}
.jide-videos-widget button.close-video-select .icon {
  width: 12px;
  height: 12px;
  padding: 0;
}
.jide-videos-widget button.close-video-select .icon path {
  stroke: #839fb9;
}
.jide-videos-widget .video-selector .content {
  display: flex;
  align-items: flex-start;
  padding: 0 1rem;
}
.jide-videos-widget .video-selector button.select-module {
  display: flex;
  flex-direction: column;
  text-transform: none;
  font-weight: bold;
  padding: 0;
  align-items: stretch;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  margin: 1rem 0;
  border-radius: 8px;
  overflow: hidden;
  height: 112.5px;
  width: 65px;
  transition: all 0.3s;
  box-shadow: none;
}
.jide-videos-widget .video-selector button.select-module:not(:disabled) {
  background: hsl(201, 100%, 55%);
}
.jide-videos-widget .video-selector button.select-module:not(:disabled):hover {
  box-shadow: 0px 10px 30px #b5d2ec;
  transform: translateY(-1px);
}
.jide-videos-widget .video-selector button.select-module .module-name {
  padding: 12px;
  text-align: center;
  min-height: 41px;
  box-sizing: border-box;
}
.jide-videos-widget .video-selector button.select-module:disabled .module-name {
  background: #e8e8e8;
}
.jide-videos-widget
  .video-selector
  button.select-module:not(:disabled)
  .module-name {
  background: hsl(201, 100%, 50%);
}
.jide-videos-widget .video-selector button.select-module .icon {
  height: 16px;
  width: 16px;
  padding: 12px;
  flex: 1;
  align-self: center;
}
.jide-videos-widget .video-selector button.select-module .icon polyline {
  stroke: #fff;
}
.jide-videos-widget .video-selector button.select-module:disabled .icon polyline {
  stroke: #ddd;
}
.jide-videos-widget .video-selector .video-options {
  display: flex;
  flex: 1;
  min-height: 180.5px;
  max-width: 632px;
  overflow-x: scroll;
  margin: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
}
.jide-videos-widget .video-selector .video-options.scrollable {
  margin-bottom: 2px;
}
.jide-videos-widget .video-selector .video-options:first-child {
  margin-left: 0;
}
.jide-videos-widget .video-selector .video-options.scrollable::-webkit-scrollbar {
  height: 8px;
  -webkit-appearance: none;
}
.jide-videos-widget .video-selector .video-options.scrollable {
  /* For Firefox 64+ */
  scrollbar-color: rgba(2, 36, 63, 0.3) hsl(208, 100%, 53%);
}
.jide-videos-widget
  .video-selector
  .video-options.scrollable::-webkit-scrollbar-thumb {
  background: rgba(2, 36, 63, 0.3);
  border-radius: 4px;
}
.jide-videos-widget .video-selector .loading-videos-spinner-container,
.jide-videos-widget .video-selector .no-videos-msg {
  width: 200px;
  height: 112.5px;
  box-sizing: border-box;
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.jide-videos-widget .video-selector .no-videos-msg {
  color: hsl(201, 27%, 52%);
  text-align: center;
  border-radius: 10px;
  font-size: 14px;
  background: #dbeeff;
  border: none;
  letter-spacing: 0.5px;
}

/********** Styles for individual Video Options **********/

.jide-video-option {
  margin-right: 1rem;
  padding-bottom: 1rem;
  width: 200px;
  flex-shrink: 0;
}
.jide-video-option:last-child {
  margin-right: 0;
}
.jide-video-option .video-container {
  border-radius: 10px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.jide-video-option .video-container .video-overlay {
  background: linear-gradient(
    hsla(207, 100%, 13%, 0.65),
    hsla(207, 100%, 24%, 0.65)
  );
}
.jide-video-option .video-container .video-overlay .icon {
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  padding: 20px;
  transition: transform 0.2s;
}
.jide-video-option .video-container .video-overlay .icon path {
  stroke: #fff;
}
.jide-video-option .video-container .video-overlay:hover .icon path {
  stroke: hsl(201, 100%, 50%);
}
.jide-video-option .video-container iframe {
  transition: transform 0.2s;
}
.jide-video-option .video-container:hover iframe {
  transform: scale(1.01);
}
.jide-video-option .video-info {
  display: flex;
  align-items: flex-start;
  margin-top: 0.5rem;
}
.jide-video-option .video-title {
  font-size: 12px;
  font-weight: bold;
  color: hsl(202, 94%, 19%);
  margin-right: 1rem;
  transition: transform 0.2s;
  cursor: pointer;
}
.jide-video-option .video-title:hover {
  color: hsl(201, 100%, 50%);
  transform: scale(1.02);
}
.jide-video-option .video-duration {
  margin-left: auto;
}
.jide-video-option .video-duration .icon {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.jide-video-option .video-duration .duration-text {
  font-size: 10px;
  width: 40px;
}
