.checkbox-v2-container label {
  cursor: pointer;
  position: relative;
}

.checkbox-v2-container-disabled label {
  cursor: default;
}

.checkbox-v2-container {
  margin: 0 15px;
}

.checkbox-v2-container label span {
  display: inline-block;
  position: relative;
  border: 2px solid hsl(208, 100%, 53%);
  background: #e2f4fc;
  border-radius: 4px;
  width: 18px;
  height: 18px;
  transform-origin: center;
  vertical-align: -6px;
  transition: background-color 150ms,
    transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
}

.dark label span {
  display: inline-block;
  position: relative;
  border: 2px solid hsl(208, 100%, 53%);
  background: #E2E1ED;
  border-radius: 4px;
  width: 18px;
  height: 18px;
  transform-origin: center;
  vertical-align: -6px;
  transition: background-color 150ms,
    transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
}

.checkbox-v2-container-disabled label span {
  background: rgba(216, 232, 238, 0.2);
  border: 2px solid hsl(208, 36%, 87%);
  box-shadow: none;
}

.checkbox-v2-container-small {
  margin: 0 16px 0 57px;
}

.checkbox-v2-container-small label span {
  width: 13px;
  height: 13px;
  border: 2px solid hsl(208, 100%, 53%);
  box-shadow: none;
  border-radius: 4px;
  top: -2px;
}

.checkbox-v2-container-small.checkbox-v2-container-disabled label span {
  border: 2px solid hsl(208, 36%, 87%);
  background: rgba(216, 232, 238, 0.2);
}

.checkbox-v2-container-small.checkbox-v2-container-disabled {
  margin: 0 24px 0 29px;
}

.checkbox-v2-container label span:before {
  content: '';
  width: 0px;
  height: 2px;
  border-radius: 2px;
  background: #caecfc;
  position: absolute;
  transform: rotate(45deg);
  top: 9px;
  left: 3px;
  transition: width 50ms ease 50ms;
  transform-origin: 0% 0%;
}

.checkbox-v2-container label span:after {
  content: '';
  width: 0;
  height: 2px;
  border-radius: 2px;
  background: #caecfc;
  position: absolute;
  transform: rotate(315deg);
  top: 13px;
  left: 6px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
}

.dark label span:before {
  content: '';
  width: 0px;
  height: 2px;
  border-radius: 2px;
  background: #292563;
  position: absolute;
  transform: rotate(45deg);
  top: 9px;
  left: 3px;
  transition: width 50ms ease 50ms;
  transform-origin: 0% 0%;
}

.dark label span:after {
  content: '';
  width: 0;
  height: 2px;
  border-radius: 2px;
  background: #292563;
  position: absolute;
  transform: rotate(315deg);
  top: 13px;
  left: 6px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
}

.checkbox-v2-container.checkbox-v2-container-small label span:before {
  top: 6px;
  left: 3px;
  width: 0;
  background: #caecfc;
}

.checkbox-v2-container.checkbox-v2-container-small label span:after {
  top: 9px;
  left: 4px;
  width: 0;
  background: #caecfc;
}

.checkbox-v2-container label:hover span {
  transform: scale(1.25);
}

.checkbox-v2-container label:hover span:before {
  width: 5px;
  transition: width 100ms ease;
}

.checkbox-v2-container label:hover span:after {
  width: 9px;
  transition: width 150ms ease 100ms;
}

.checkbox-v2-container input[type='checkbox'] {
  display: none;
}

.checkbox-v2-container input[type='checkbox']:checked + label span {
  background-color: hsl(208, 100%, 53%);
  box-shadow: none;
}

.dark input[type='checkbox']:checked + label span {
  background-color: #292563;
}

.checkbox-v2-container.checkbox-v2-container-disabled
  input[type='checkbox']:checked
  + label
  span {
  background: transparent;
  border: 2px solid transparent;
}

.checkbox-v2-container-small input[type='checkbox']:checked + label span {
  border: 2px solid hsl(208, 100%, 53%);
  background: hsl(208, 100%, 53%);
  box-shadow: none;
}

.checkbox-v2-container-small.checkbox-v2-container-disabled
  input[type='checkbox']:checked
  + label
  span {
  background: transparent;
  border: 2px solid transparent;
}

.checkbox-v2-container input[type='checkbox']:checked + label span:before {
  width: 7px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.checkbox-v2-container input[type='checkbox']:checked + label span:after {
  width: 13px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.checkbox-v2-container.checkbox-v2-container-disabled
  input[type='checkbox']:checked
  + label
  span:before,
.checkbox-v2-container.checkbox-v2-container-disabled
  input[type='checkbox']:checked
  + label
  span:after {
  background: rgb(46, 203, 93);
}
.checkbox-v2-container label:hover span:before {
  width: 7px;
  transition: width 150ms ease 100ms;
}
.checkbox-v2-container label:hover span:after {
  width: 13px;
  transition: width 150ms ease 100ms;
}

.checkbox-v2-container.checkbox-v2-container-disabled label:hover span:before,
.checkbox-v2-container.checkbox-v2-container-disabled label:hover span:after {
  width: 0;
}

.checkbox-v2-container-small label:hover span:before {
  width: 5px;
}
.checkbox-v2-container-small label:hover span:after {
  width: 9px;
}
.checkbox-v2-container-small.checkbox-v2-container-disabled label:hover span:before {
  width: 5px;
}
.checkbox-v2-container-small.checkbox-v2-container-disabled label:hover span:after {
  width: 9px;
}
.checkbox-v2-container-small.checkbox-v2-container-disabled label span:before,
.checkbox-v2-container-small.checkbox-v2-container-disabled label span:after {
  background: transparent;
}
.checkbox-v2-container-small.checkbox-v2-container-disabled
  input[type='checkbox']:checked
  + label
  span:before,
.checkbox-v2-container-small.checkbox-v2-container-disabled
  input[type='checkbox']:checked
  + label
  span:after {
  background: hsl(208, 100%, 53%);
}

.checkbox-v2-container-small input[type='checkbox']:checked + label span:after {
  width: 9px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.checkbox-v2-container-small input[type='checkbox']:checked + label span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.checkbox-v2-container-disabled label:hover span {
  transform: scale(1);
}

@media only screen and (min-width: 600px) {
  .checkbox-v2-container {
    margin: 0 33px;
  }
  .checkbox-v2-container-small {
    margin: 0 20px 0 63px;
  }
}
