.jsb {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--jsb-bg);

  --jsb-jide-darkblue: #0f3a52;
  --jsb-bg: rgba(36, 37, 47, 1);
  --jsb-bg-light: rgb(48, 51, 63);
  --jsb-white: rgba(250, 250, 240, 1);
  --jsb-white-faint: rgba(250, 250, 240, 0.05);
  --jsb-white-light: rgba(250, 250, 240, 0.3);
  --jsb-white-medium: rgba(250, 250, 240, 0.6);
}
.jsb.jsb-expanded {
  min-width: 310px;
}
.jsb.jsb-closed {
  width: 62px;
}
.jsb div {
  box-sizing: border-box;
}

.jsb-bar {
  cursor: pointer;
  display: flex;
  height: 50px;
  min-height: 50px;
  width: 100%;
  background-color: var(--jsb-jide-darkblue);
  color: var(--jsb-white-medium);
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  font-size: 22px;
}
.jsb-bar:hover {
  color: var(--jsb-white);
}
.jsb-fullscreen {
  width: 200px;
  z-index: 10;
}
.jsb-bar-text {
  margin-left: 12px;
  font-size: 16px;
}

.jsb-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
}

.jsb-tabs {
  display: flex;
  flex-direction: column;
  background-color: var(--jsb-bg);
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
}
.jsb-expanded .jsb-tabs {
  border: 0px;
  width: 60px;
}
.jsb-closed .jsb-tabs {
  border-right: 2px solid var(--jsb-bg-light);
  width: 62px;
}
.jsb-tab {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 100%;
  height: 44px;
  border-radius: 3px;
  margin-bottom: 5px;
}
.jsb-tab.jsb-tab-active {
  color: var(--jsb-bg);
  background-color: var(--jsb-white);
}
.jsb-tab.jsb-tab-inactive {
  color: var(--jsb-white-light);
}
.jsb-tab.jsb-tab-inactive:hover {
  color: var(--jsb-white-medium);
  background-color: var(--jsb-white-faint);
}
.jsb-tab.jsb-tab-disabled,
.jsb-tab.jsb-tab-disabled:hover {
  cursor: not-allowed;
}

.jsb-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.jsb-closed .jsb-content {
  display: none;
}
.jsb-content-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.jsb-content-pane {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--jsb-bg-light);
}
.jsb-content-pane.jsb-content-active {
  z-index: 3;
}
.jsb-content-pane.jsb-content-inactive {
  z-index: 1;
  display: none;
}
.jsb-content-pane-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px;
}
.jsb-content-scrollable {
  /* make the pane scrollable */
  flex-grow: 1;
  overflow: auto;
  min-height: 0;
}
.jsb-content-pane-notice {
  display: flex;
  flex-direction: row;
  padding: 1em;
  border-radius: 3px;
  margin-top: 10px;
  background-color: rgba(40, 42, 54, 0.5);
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  align-items: center;
  justify-content: center;
}
.jsb-content-pane-loading {
  font-size: 18px;
}

.jsb-content-card {
  display: flex;
  flex-direction: row;
  padding: 8px;
  cursor: pointer;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 15px;
  letter-spacing: 0.3pt;
  font-family: 'Open Sans', sans-serif;
  color: var(--jsb-white);
  background-color: var(--jsb-white-faint);
  overflow: hidden;
}

.jsb-file {
  padding-left: 0px;
  letter-spacing: 0pt;
}
.jsb-file:last-child {
  margin-bottom: 0px;
}
.jsb-file-left {
  display: flex;
  width: 45px;
  min-width: 45px;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.jsb-file-mid {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
}
.jsb-file-mid-upper {
  font-size: 14px;
}
.jsb-file-mid-lower {
  font-size: 10px;
}
.jsb-file-filename {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.jsb-file-right {
  display: flex;
  width: 30px;
  min-width: 30px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  visibility: hidden;
}
.jsb-file.jsb-file-active {
  color: var(--jsb-bg-light);
  background-color: var(--jsb-white);
  border-radius: 3px;
}
/* .jsb-file:hover .jsb-file-right {
  visibility: visible;
}
.jsb-file-right:hover {
  color: rgba(255, 100, 100, 0.5) !important;
} */

.jsb-file-mgmt {
  flex-direction: column;
  border-radius: 3px;
  padding: 10px 0px;
}
.jsb-file-mgmt-top {
  flex-direction: column;
  display: flex;
  margin-bottom: 10px;
}
.jsb-file-mgmt-top input {
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px;
  border: none;
  color: var(--jsb-bg-light);
  background-color: var(--jsb-white);
  padding: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  letter-spacing: 0.3pt;
}
.jsb-file-mgmt-top input.jsb-invalid {
  color: #d52224;
}
.jsb-file-mgmt-bottom {
  display: flex;
}
.jsb-file-mgmt-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  flex-grow: 1;
  background-color: var(--jsb-white-faint);
  color: var(--jsb-white-light);
  border-radius: 3px;
  margin-right: 10px;
}
.jsb-file-mgmt-button:last-child {
  margin-right: 0px;
}
.jsb-file-mgmt-button:hover {
  background-color: var(--jsb-white-light);
  color: var(--jsb-white);
}
.jsb-file-mgmt-button.jsb-active {
  background-color: var(--jsb-white);
  color: var(--jsb-bg-light);
}

.jsb-icontext {
  padding-left: 0px;
}
.jsb-icontext-left {
  display: flex;
  width: 45px;
  align-items: center;
  justify-content: center;
}
.jsb-icontext-right {
  display: inline-block;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  word-wrap: break-word;
}

.jsb-text {
}

.jsb-content-header {
  font-family: 'Gotham', sans-serif;
  font-size: 14px;
  color: var(--jsb-white);
  letter-spacing: 2px;
  padding-left: 5px;
  margin-bottom: 5px;
}
.jsb-content-header-small {
  font-family: 'Gotham', sans-serif;
  font-size: 11px;
  color: var(--jsb-white);
  letter-spacing: 2px;
  padding-left: 2px;
  margin-bottom: 5px;
}

.jsb-space {
  height: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.jsb-toggle {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 60px;
  height: 30px;
  margin-top: 10px;
  padding-left: 15px;
}
.jsb-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.jsb-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.jsb-slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .jsb-slider {
  background-color: #3da5ff;
}
input:focus + .jsb-slider {
  box-shadow: 0 0 1px #3da5ff;
}
input:checked + .jsb-slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}
.jsb-slider.jsb-round {
  border-radius: 30px;
}
.jsb-slider.jsb-round:before {
  border-radius: 50%;
}

.jsb-hardreset {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 2px solid #d52224;
  padding: 10px 0px;
  color: #d52224;
  font-family: 'Gotham', sans-serif;
  letter-spacing: 0.3pt;
  font-size: 11px;
  opacity: 0.7;
  margin-top: auto;
  margin-bottom: 10px;
}
.jsb-hardreset:hover {
  opacity: 1;
  background-color: #d52224;
  color: var(--jsb-white);
}
.jsb-hardreset div {
  display: flex;
  box-sizing: border-box;
}
.jsb-hardreset-top {
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 10px;
}
.jsb-hardreset-bottom {
  align-items: center;
  justify-content: center;
}
/* overrides */
.ace-monokai .ace_gutter { 
    background: #243743!important;
    color: #627079!important;
}

.ace-monokai {
    background: #172833!important;
}

.jsb {
    --jsb-bg: #2a404f!important;
}

.jsb-closed .jsb-tabs {
    border-right: 2px solid #334856!important;
}

.ace-monokai .ace_gutter-active-line {
    background-color: #122028!important;
}

.ace-monokai .ace_marker-layer .ace_active-line {
    background: #0e1b22!important;
}

.ace-monokai {
    background: #1e2931!important;
}
.ace-monokai .ace_gutter {
    background: #242f37!important;
    color: #5f6971!important;
}

.jsb {
    --jsb-bg: #2d3942!important;
}

.jsb-closed .jsb-tabs {
    border-right: 2px solid #27333c!important;
}
