.ui-editable-react-select.ui-pop-out {
  position: relative;
}

.ui-editable-react-select.ui-pop-out .ui-editor {
  position: absolute;
  min-width: 15rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 1rem;
  z-index: 999;
}
