.jrs {
  --jrs-blue: #00a5ff;
  --jrs-red: #d33838;
  --jrs-modal-text: #325265;
  --jrs-modal-border: #c8e0eb;
  --jrs-modal-shadow: #182f3a;
  --jrs-modal-heading: #556f7a;

  display: flex;
}
.jrs div {
  display: flex;
  box-sizing: border-box;
}

.jrs-modal-container {
  position: absolute;
  top: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 30px;
}
.jrs-modal-container:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.jrs-modal {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: white;
  font-family: "Open Sans";
  color: var(--jrs-modal-text);
  overflow: hidden;
  border-radius: 12px;
  border: 2px solid var(--jrs-modal-border);
  box-shadow: 0px 10px 40px -10px var(--jrs-modal-shadow);
  width: 600px;
  z-index: 999;
}
.jrs-modal div {
  display: flex;
}
.jrs-modal-section {
  border-bottom: 2px solid rgba(0, 166, 255, 0.15);
}
.jrs-modal-section:last-child {
  border-bottom: 0px;
}
.jrs-modal-section.jrs-column {
  flex-direction: column;
}
.jrs-modal-section.jrs-row > *:last-child {
  margin-right: 0px;
}
.jrs-modal-section.jrs-column > * {
  margin-bottom: 16px;
}
.jrs-modal-section.jrs-column > *:last-child {
  margin-bottom: 0px;
}
.jrs-modal-subsection.jrs-column {
  flex-direction: column;
}
.jrs-modal-subsection.jrs-row > * {
  margin-right: 10px;
}
.jrs-modal-subsection.jrs-row > *:last-child {
  margin-right: 0px;
}
.jrs-modal-subsection.jrs-column > * {
  margin-bottom: 14px;
}
.jrs-modal-subsection.jrs-column > *:last-child {
  margin-bottom: 0px;
}
.jrs-modal-subsection.jrs-column .jrs-modal-heading1 {
  font-size: 14px;
  align-self: center;
}

.jrs-modal-heading1 {
  font-weight: 800;
  font-size: 20px;
  letter-spacing: 0.3pt;
}
.jrs-modal-heading2 {
  color: var(--jrs-modal-heading);
  font-weight: 600;
  font-size: 12px;
  flex-grow: 1;
}
.jrs-modal-heading3 {
  color: var(--jrs-modal-heading);
  font-weight: 500;
  font-size: 11px;
  flex-grow: 1;
  letter-spacing: 0.07em;
}
.jrs-modal-spacing {
  height: 20px;
}

.jrs-modal-x {
  cursor: pointer;
  flex-shrink: 0;
  align-items: center;
  margin-left: auto;
}
.jrs-modal-x-inner {
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  border-radius: 13px;
  opacity: 0.5;
  font-size: 16px;
}
.jrs-modal-x:hover .jrs-modal-x-inner {
  opacity: 0.6;
  color: white;
  background-color: var(--jrs-red);
}

.jrs-modal-input {
  font-family: "Open Sans";
  font-size: 16px;
  color: var(--jrs-modal-text);
  padding: 8px;
  border: 2px solid var(--jrs-blue);
  border-radius: 4px;
}

.jrs-modal-header {
  padding: 18px;
}

.jrs-section {
  padding: 24px;
  background: #f6fcff;
}

.jrs-modal-heading4 {
  font-weight: 800;
}

.jrs-modal-heading5 {
  font-weight: 600;
  font-size: 18px;
  opacity: 0.6;
  justify-content: center;
}

.jrs-modal-subsection.jrs-column.jrs-cta {
  border-top: 1px solid #d1ebf8;
  padding-top: 12px;
  margin-top: 8px;
  margin-bottom: 18px;
}

.jrs-cta .jrs-modal-heading4 {
  margin: 4px 0 12px;
}
.jrs-modal-subsection.jrs-column.jrs-cta .jrs-modal-line {
  margin: 0 0 8px;
}

.jrs-modal-subsection.jrs-buttons.jrs-row * {
  font-size: 12px;
}

.jrs-modal-spinner-container {
  position: absolute;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.jrs-modal-spinner {
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: white;
  border-radius: 10px;
  height: 120px;
  width: 120px;
}

.jrs-uploadprogress {
  padding: 10px;
}
