.learner-workspace-zero-state {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1rem;
}
.learner-workspace-zero-state .bg-image {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.learner-workspace-zero-state .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: hsla(207, 100%, 17%, 0.8);
}
.learner-workspace-zero-state .card-v3 {
  position: relative;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 4px solid hsl(207, 100%, 82%);
  overflow: hidden;
  background: hsl(203, 100%, 97%);
}
.learner-workspace-zero-state .main-content-wrapper {
  position: relative;
}
.learner-workspace-zero-state .main-content {
  padding: 1rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
}
.learner-workspace-zero-state .bottom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.learner-workspace-zero-state .bottom-content .darker-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 40%;
  background: hsl(201, 100%, 94%);
}

.learner-workspace-zero-state .bottom-content .preview-image {
  border-radius: 11px;
  border: 8px solid hsl(201, 100%, 91%);
}

.learner-workspace-zero-state h1,
.learner-workspace-zero-state p {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  color: hsl(200, 76%, 13%);
}
.learner-workspace-zero-state h1 {
  color: hsl(200, 76%, 13%);
  margin: 1.5rem 0;
}
.learner-workspace-zero-state .paragraphs {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.learner-app .learner-workspace-zero-state p {
  color: hsl(201, 33%, 41%);
  margin-bottom: 1rem;
}
.learner-workspace-zero-state p:last-child {
  margin-bottom: 0;
}
.learner-workspace-zero-state .buttons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 1.5rem;
}
.learner-workspace-zero-state a.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Gotham', sans-serif;
  font-weight: bold;
  font-size: 12px;
  padding: 6px;
  border-radius: 6px;
  border: none;
  letter-spacing: 2px;
  margin-top: 0;
  text-decoration: none;
}
.learner-workspace-zero-state a.button:last-child {
  background: hsl(200, 50%, 25%);
  margin-top: 1rem;
}
.learner-workspace-zero-state a.button span {
  margin: 0 auto;
}
.learner-workspace-zero-state a.button .icon {
  background: hsla(0, 0%, 100%, 0.15);
  border-radius: 4px;
}
.learner-workspace-zero-state a.button .icon path {
  fill: #fff;
}
.learner-workspace-zero-state .bottom-content img {
  width: 80%;
  max-width: 600px;
  position: relative;
  top: 10px;
  box-shadow: 0 4px 10px hsla(0, 0%, 0%, 0.25);
}

#learner-workspace-zero-state-squiggle-1 {
  width: 150px;
  top: 0.8rem;
  left: -1rem;
}
#learner-workspace-zero-state-semi-circle-1 {
  width: 120px;
  top: 5rem;
  right: -3rem;
  transform: rotate(180deg);
}
#learner-workspace-zero-state-semi-circle-2 {
  width: 120px;
  bottom: -1rem;
  left: -2.5rem;
}
#learner-workspace-zero-state-squiggle-2 {
  width: 150px;
  bottom: 3rem;
  right: -2rem;
}

@media only screen and (min-width: 480px) {
  .learner-workspace-zero-state {
    padding: 3rem 2rem;
  }
  .learner-workspace-zero-state .buttons {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .learner-workspace-zero-state a.button {
    flex: 1;
    max-width: 240px;
  }
  .learner-workspace-zero-state a.button:first-child {
    margin-right: 0.5rem;
  }
  .learner-workspace-zero-state a.button:last-child {
    margin-top: 0;
    margin-left: 0.5rem;
  }
  .learner-app .learner-workspace-zero-state p {
    max-width: 620px;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 900px) {
  .learner-workspace-zero-state {
    padding: 3rem 5rem;
  }
  .learner-workspace-zero-state .buttons {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .learner-workspace-zero-state a.button {
    flex: 1;
    max-width: 220px;
  }
  .learner-workspace-zero-state a.button:first-child {
    margin-right: 0.5rem;
  }
  .learner-workspace-zero-state a.button:last-child {
    margin-top: 0;
    margin-left: 0.5rem;
  }
  .learner-workspace-zero-state .card-v3 {
    max-width: 1280px;
    flex: 1;
  }
  #learner-workspace-zero-state-squiggle-1 {
    width: 180px;
    top: 17%;
    left: 9%;
  }
  #learner-workspace-zero-state-semi-circle-1 {
    top: -2rem;
    right: 13%;
  }
  #learner-workspace-zero-state-semi-circle-2 {
    bottom: 1rem;
    left: -2rem;
  }
  #learner-workspace-zero-state-squiggle-2 {
    width: 180px;
    bottom: 3rem;
    right: -1rem;
  }
}
