.jide-wrapper-admin.jide-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: auto;
}

.jide-wrapper-admin.jide-main-content .jide {
  min-width: 1150px;
}
