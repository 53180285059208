.whiteboard-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: hsl(197, 100%, 99%);
  flex: 1;
}

.whiteboard-container.hidden {
  display: none;
}

.whiteboard-container .whiteboard {
  flex: 1;
  display: flex;
  position: relative;
}

.whiteboard-container .whiteboard-controls-container {
  margin: 0 14px;
  border-bottom: 1px solid hsl(208, 36%, 87%);
}

.whiteboard-container .whiteboard-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  font-size: 12px;
}
.whiteboard-container .whiteboard-controls.condense {
  padding: 8px 0;
}
.whiteboard-container .whiteboard-controls .juniverse-heading-container {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  margin-top: 0.5rem;
}
.whiteboard-container
  .whiteboard-controls
  .juniverse-heading-container
  .heading-img-container {
  max-height: 2.5rem;
  margin-right: 1rem;
}
.whiteboard-container
  .whiteboard-controls
  .juniverse-heading-container
  .heading-img-container
  img {
  height: 2.5rem;
}
.whiteboard-container
  .whiteboard-controls
  .juniverse-heading-container
  span#view-work {
  font-size: 1rem;
  font-weight: 600;
  color: #122b39;
}
.whiteboard-container .whiteboard-controls .whiteboard-control {
  margin-right: 12px;
}

button.whiteboard-control {
  background: hsl(197, 60%, 94%);
  padding: 6px;
  border: none;
  color: hsl(208, 100%, 11%);
  font-weight: bold;
}

.whiteboard-control.icon-button {
  line-height: 0;
}

button.whiteboard-control.active {
  background: hsl(208, 100%, 53%);
}

button.whiteboard-control .icon {
  height: 12px;
  width: 12px;
  padding: 0;
}

button.whiteboard-control .icon path {
  fill: hsl(208, 100%, 11%);
}

button.whiteboard-control .icon line,
button.whiteboard-control.stroke-only .icon path,
button.whiteboard-control.stroke-only .icon rect,
button.whiteboard-control.stroke-only .icon ellipse,
button.whiteboard-control .icon polyline {
  stroke: hsl(208, 100%, 11%);
}

button.whiteboard-control.active .icon path {
  fill: #fff;
}

button.whiteboard-control.active.stroke-only .icon path,
button.whiteboard-control.stroke-only .icon rect,
button.whiteboard-control.stroke-only .icon ellipse,
button.whiteboard-control.active .icon line,
button.whiteboard-control.active .icon polyline {
  stroke: #fff;
}

.whiteboard-container .whiteboard-page-nav {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.whiteboard-container .whiteboard-page-nav button.whiteboard-control .icon {
  height: 8px;
  width: 8px;
}

.whiteboard-page-nav button.whiteboard-control:last-child {
  margin-right: 0;
}

button.whiteboard-control:disabled {
  color: hsl(208, 38%, 75%);
  border: none;
  background: hsl(197, 60%, 94%);
}

button.whiteboard-control.stroke:disabled .icon path,
button.whiteboard-control:disabled .icon polyline {
  stroke: hsl(208, 38%, 75%);
}

button.whiteboard-control:disabled .icon path {
  fill: hsl(208, 38%, 75%);
}

.whiteboard-container .wb-control-select {
  line-height: 0;
  position: relative;
}

.whiteboard-container .select-dropdown {
  line-height: 0;
  position: absolute;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 6px 10px -2px hsl(208, 46%, 78%);
  z-index: 2;
  padding: 4px;
}

.whiteboard-container .select-dropdown > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.whiteboard-container .select-dropdown .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.whiteboard-container .wb-control-select button {
  position: relative;
}

.whiteboard-container .select-dropdown button {
  margin: 3px;
}
.whiteboard-container .shape-select .select-dropdown button:not(.active) {
  background: none;
}
.whiteboard-container
  .shape-select
  .select-dropdown
  button.stroke-only:not(.active)
  rect,
.whiteboard-container
  .shape-select
  .select-dropdown
  button.stroke-only:not(.active)
  ellipse {
  stroke: hsl(208, 100%, 11%);
}
.whiteboard-container .shape-select .select-dropdown button:not(.active):hover {
  background: hsl(208, 80%, 92%);
}
.whiteboard-container .select-dropdown button.active {
  box-shadow: none;
}
.whiteboard-container .color-select .select-dropdown button {
  background: none;
  box-shadow: none;
  padding: 0;
}

.whiteboard-container .color-select .select-dropdown button.active {
  background: hsl(208, 80%, 92%);
}

.whiteboard-container .select-dropdown button .color-indicator {
  transition: transform 0.2s;
}

.whiteboard-container .select-dropdown button:not(.active):hover .color-indicator {
  transform: scale(1.2);
}

.whiteboard-container .color-indicator.outer {
  border-radius: 50%;
  height: 12px;
  width: 12px;
  opacity: 0.5;
}

.whiteboard-container .color-indicator.inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whiteboard-container .color-indicator.inner > div {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.whiteboard-container .select-dropdown .color-indicator.outer {
  height: 18px;
  width: 18px;
  margin: 4px;
}

.whiteboard-container .select-dropdown .color-indicator.inner > div {
  height: 14px;
  width: 14px;
}

.whiteboard-container .whiteboard > textarea {
  -webkit-appearance: none;
  box-shadow: none;
  border: 1px solid hsl(208, 36%, 87%);
  position: absolute;
  width: 400px;
  padding: 6px;
  top: 100px;
  left: 100px;
  box-shadow: 0 6px 10px -4px hsl(208, 46%, 78%);
  box-sizing: border-box;
  z-index: 1002;
  font-size: 16px;
}

.whiteboard-container .whiteboard > svg {
  flex: 1;
}

.whiteboard-container .whiteboard > svg path {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.whiteboard-container .whiteboard > svg text {
  display: block;
  font-size: 16px;
  white-space: pre;
}

.whiteboard-container .whiteboard > svg path {
  stroke-width: 3px;
}

.whiteboard-container .whiteboard > svg image.wb-image-delete-btn {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.whiteboard-container .whiteboard > svg image.wb-grid-delete-btn {
  cursor: pointer;
  transition: all 0.2s;
  width: 16px;
  height: 16px;
}

.whiteboard-container .whiteboard > svg image.wb-image-delete-btn:hover,
.whiteboard-container .whiteboard > svg image.wb-grid-delete-btn:hover {
  width: 18px;
  height: 18px;
}

.whiteboard-container .whiteboard > svg rect.wb-grid-delete-btn-content {
  fill: #fff;
}

.whiteboard-container .whiteboard > svg image.wb-grid {
  pointer-events: none;
}

.whiteboard-container:not(.image-tool) .whiteboard > svg image.wb-image-delete-btn,
.whiteboard-container:not(.grid-tool) .whiteboard > svg image.wb-grid-delete-btn {
  display: none;
}

.whiteboard-container .whiteboard .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsla(208, 14%, 24%, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.whiteboard-container .page-count-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.whiteboard-container .page-count-container .sk-fading-circle {
  margin-right: 0;
}

.whiteboard-container .ws-error-alert .alert-banner-content {
  padding: 16px;
}

.whiteboard-container .ws-error-alert .alert-banner-text {
  max-width: 400px;
}

.whiteboard-container .ws-error-alert .lost-connection {
  display: flex;
  align-items: center;
}

.whiteboard-container .svg-defs {
  position: absolute;
  height: 0;
  width: 0;
}

.whiteboard-container .resizers {
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
}

.whiteboard-container .resizers .right-handle {
  display: none;
}
.whiteboard-container .resizers .right-handle.active {
  display: block;
  position: absolute;
  width: 0.5em;
  top: 1em;
  bottom: 1.6em;
  right: 0.8em;
  cursor: move;
}

.whiteboard-container .resizers .bottom-handle {
  display: none;
}
.whiteboard-container .resizers .bottom-handle.active {
  display: block;
  position: absolute;
  height: 0.5em;
  left: 1em;
  bottom: 0.8em;
  right: 1.6em;
  cursor: move;
}

.whiteboard-container .resizers .left-handle {
  display: none;
}
.whiteboard-container .resizers .left-handle.active {
  display: block;
  position: absolute;
  width: 0.5em;
  top: 1em;
  bottom: 1em;
  left: 0.8em;
  cursor: move;
}

.whiteboard-container .resizers .top-handle {
  display: none;
}
.whiteboard-container .resizers .top-handle.active {
  display: block;
  position: absolute;
  height: 0.5em;
  left: 1em;
  top: 0.8em;
  right: 1em;
  cursor: move;
}

.whiteboard-container .resizers .corner-handle {
  display: none;
}

.whiteboard-container .resizers .corner-handle.active {
  display: block;
  background-color: white;
  width: 1em;
  height: 1em;
  position: absolute;
  border: 2px solid #9ddaff;
  border-radius: 0.25em;
}

.whiteboard-container .resizers .ne-handle.active {
  top: 0.5em;
  right: 0.45em;
  cursor: nesw-resize;
}
.whiteboard-container .resizers .nw-handle.active {
  top: 0.5em;
  left: 0.45em;
  cursor: nwse-resize;
}
.whiteboard-container .resizers .se-handle.active {
  bottom: 0.5em;
  right: 0.45em;
  cursor: nwse-resize;
}
.whiteboard-container .resizers .sw-handle.active {
  bottom: 0.5em;
  left: 0.45em;
  cursor: nesw-resize;
}

.whiteboard-container .image-container {
  cursor: move;
}

.whiteboard-container .image-container,
.whiteboard-container .text-container {
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
  overflow: hidden;
  border: 2px dashed transparent;
}

.whiteboard-container .image-container,
.whiteboard-container .text-container.active {
  border: 2px dashed #9ddaff;
}

.whiteboard-container .text-input {
  min-width: 1em; /* Without this, width could be zero and cursor will not show */
  display: inline-block;
  line-height: 1.2;
}

.wb-control-select > .select-dropdown > .ui-field > * {
  min-width: 400px;
}

@media only screen and (max-width: 460px) {
  .wb-control-select > .select-dropdown {
    position: fixed;
    left: 0px;
    right: 0px;
  }
  .wb-control-select > .select-dropdown > .ui-field > * {
    width: 100%;
  }
}
