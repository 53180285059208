.pyturt {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #ebf6ff;
  flex-grow: 1;
}
.pyturt.pyturt-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 10px 10vmax rgba(0, 0, 0, 0.75);
  animation: grow 0.25s ease-out;
}
@keyframes grow {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}
.pyturt.pyturt-horizontal {
  flex-direction: row;
}
.pyturt.pyturt-vertical {
  flex-direction: column;
}

.pyturt-left-pane {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  padding: 0px;
}

.pyturt-code-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0px;
  background: #272822;
}
.pyturt-code-editor-bar {
  display: flex;
  height: 50px;
  min-height: 50px;
  width: 100%;
  justify-content: space-between;
  background-color: #0f3a52;
  color: white;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10px;
}
.pyturt-code-editor-bar-left {
  display: flex;
  height: 100%;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}
.pyturt-code-editor-bar-left-fstoggle {
  display: flex;
  height: 100%;
  width: 60px;
  background-color: #0f3a52;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.6);
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  font-size: 22px;
}
.pyturt-code-editor-bar-left-fstoggle:hover {
  cursor: pointer;
  color: white;
}
.pyturt-code-settings {
  display: flex;
  height: 85%;
  width: 30px;
  font-family: 'Gotham', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  margin-right: 10px;
  justify-content: center;
  align-self: flex-end;
  cursor: pointer;
  background-color: transparent;
  visibility: hidden;
}
.pyturt-code-editor-bar-right {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  align-items: center;
}
.pyturt-code-controls {
  display: flex;
  height: 60%;
  width: 80px;
  font-family: 'Gotham', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: white;
  padding: 0px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}
.pyturt-code-controls > div {
  padding-top: 0px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pyturt-code-controls.pyturt-code-lock {
  width: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  cursor: auto;
}
.pyturt-code-controls.pyturt-code-sync {
  width: 32px;
  background-color: rgb(34 75 98);
  color: rgba(255, 255, 255, 0.7);
}
.pyturt-code-controls.pyturt-code-sync.pyturt-save-error {
  background-color: rgba(230, 102, 104, 0.4);
}
.pyturt-code-controls.pyturt-code-sync.pyturt-is-syncing {
  animation: colorcycle 1s;
  -moz-animation: colorcycle 1s infinite; /* Firefox */
  -webkit-animation: colorcycle 1s infinite; /* Safari and Chrome */
}
@keyframes colorcycle /* default */ {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  50% {
    background-color: rgb(61, 165, 255, 0.4);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
@-moz-keyframes colorcycle /* Firefox */ {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  50% {
    background-color: rgb(61, 165, 255, 0.4);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
@-webkit-keyframes colorcycle /* Safari and Chrome */ {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  50% {
    background-color: rgb(61, 165, 255, 0.4);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.pyturt-code-controls.pyturt-code-run {
  background-color: #3da5ff;
}
.pyturt-code-controls.pyturt-code-stop {
  background-color: #d52224;
}
.pyturt-code-controls.pyturt-active {
}
.pyturt-code-controls.pyturt-inactive {
  background-color: rgb(34 75 98);
  color: rgb(108 145 166);
  cursor: auto;
}
.pyturt-code-controls.pyturt-code-stop-in-progress {
  background-color: rgba(213, 34, 37, 0.67) !important;
  cursor: auto;
}
.pyturt-code-editor-ace {
  display: flex;
  width: 100%;
  height: 100%;
}

.pyturt-code-editor-ace.new-horizons {
  padding-top: 15px;
  background-color: #282a36;
}

.pyturt-right-pane {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  box-sizing: border-box;
  align-items: stretch;
  background-color: #002b36;
}
.pyturt-code-output {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
}

.pyturt-code-output-bar {
  display: flex;
  flex-direction: row;
  align-content: center;
  height: 50px;
  background-color: #f1fafd;
  justify-content: space-between;
  box-sizing: border-box;
}
.pyturt-code-output-bar-left {
  display: flex;
  height: 100%;
  align-content: center;
}

.pyturt-code-output-bar-left > * {
  border-right: 2px solid #e4f3f8;
}

.pyturt-code-output-bar-right {
  display: flex;
  height: 100%;
}
.pyturt-code-output-tab {
  display: flex;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  color: #002b36;
  z-index: 3;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding-left: 18px;
  padding-right: 18px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
}
.pyturt-code-output-tab.pyturt-canvas-tab {
}
.pyturt-code-output-tab.pyturt-console-tab {
}
.pyturt-code-output-tab.pyturt-instructions-tab {
}
.pyturt-code-output-tab.pyturt-active {
  background-color: white;
  color: #00a4ff;
  font-weight: 800;
  z-index: 3;
}
.pyturt-code-output-tab.pyturt-inactive {
  color: #3a6d7d;
  background-color: transparent;
  font-weight: 600;
  z-index: 0;
}
.pyturt-right-pane .pyturt-inactive .pyturt-iconBox:not(.pyturt-errorBox) {
    background: #def5fd;
}
.pyturt-code-output-tab.pyturt-inactive:hover {
  color: #002b36;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 3;
}
.pyturt-code-output-tab svg {
}
.pyturt-iconBox {
  display: flex;
  background-color: #c8edf7;
  color: #03a5ff;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 13px;
}
.pyturt-iconBox.pyturt-errorBox {
  color: white;
  background-color: #d52224;
}

.pyturt-code-output-main {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  z-index: 0;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.pyturt-code-output-elements-wrapper {
  display: block;
  flex: 1;
  position: relative;
  box-sizing: border-box;
}
.pyturt-code-output-element {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  padding: 30px;
  box-sizing: border-box;
}
.pyturt-code-output-element.pyturt-active {
  z-index: 1;
}
.pyturt-code-output-element.pyturt-inactive {
  z-index: 0;
}
.pyturt-turtle-canvas {
  background-color: white;
  box-sizing: border-box;
  padding: 0px !important;
  border: 12px solid rgba(15, 143, 255, 0.01);
  overflow: hidden;
}
.pyturt-turtle-canvas.pyturt-running {
  border: 12px solid rgba(15, 143, 255, 0.5);
}
.pyturt-console-output {
  margin: 0px;
  background-color: white;
  color: #002b36;
  font-size: 14px;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro',
    monospace;
  overflow: auto;
  box-sizing: border-box;
}
.pyturt-instructions-pane {
  margin: 0px;
  background-color: white;
  color: #002b36;
  font-size: 14px;
  overflow: auto;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}
.pyturt-instructions-pane-content {
  width: 100%;
}
.pyturt-instructions-pane-content img {
  width: 100%;
}
.pyturt-instructions-pane-content video {
  width: 100%;
}
.pyturt-instructions-pane .header-tag-v2 {
  min-height: 28px;
  float: right;
  padding: 0 12px;
}
.pyturt-instructions-pane-content > *:first-child {
  margin-top: 0px;
}
.pyturt-instructions-pane-content.pyturt-supplemental > *:first-child {
  margin-right: 119px;
}
.pyturt-instructions-pane-content.pyturt-supplemental.easy > *:first-child {
  margin-right: 89px;
}
.pyturt-instructions-pane-content.pyturt-supplemental.medium > *:first-child {
  margin-right: 85px;
}
