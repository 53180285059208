.jide-tb {
  overflow: hidden;
  z-index: 4;
  height: 40px;
  background-color: #292563;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.jide-tb:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    border-bottom: 1px solid #00314b;
    pointer-events: none;
    z-index: 0;
}
.jide-tb.playground {
  background: #174d6c;
}
.jide-tb.playground .jide-tab-mid {
  padding: 5px 10px 9px;
}
.jide-tb .return-to-playground__button {
  display: flex;
  flex: 1 0 auto;
  height: 2.1rem;
}
.jide-tb .bottom-bar {
    margin: 0;
    transform: translateY(-1px);
    border-color: #00314b52;
}
.jide-tb div {
  display: flex;
  box-sizing: border-box;
}
.jide-tb > div {
  padding-top: 7px;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  align-items: flex-end;
}
.jide-tb.playground > div {
  position: unset;
}
.jide-tab {
  color: white;
  align-self: flex-end;
  z-index: 1;
  cursor: pointer;
  flex-shrink: 0;
}
.jide-tab * {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
}
.jide-tab:not(.active) {
    transform: translateY(-1px)
}
.jide-tab:not(.active) * {
    font-weight: 200;
}
.jide-tb.playground .jide-tab-mid {
  padding: 11px 11px 11px 0;
}
.jide-tab-mid {
  flex-direction: row;
  flex: 1;
  padding: 7px 9px 7px 0;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid hsl(201deg 100% 15%);
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  font-family: 'Gotham', sans-serif;
  font-size: 12px;
  font-weight: normal;
  background: hsl(202, 97%, 37%);
  color: rgba(255, 255, 255, 0.7);
}
.jide-tab-mid.teacher-view-mode {
  padding: 9px;
}
.jide-tab:not(.active):hover .jide-tab-mid {
  transition: color 0.2s;
  color: rgba(255, 255, 255, 0.8);
}
.jide-tab.active .jide-tab-mid {
  background: hsl(201, 69%, 19%);
  border: 1px solid hsl(201deg 100% 15%);
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  border-radius: 8px 8px 0 0;
  padding: 6px 10px 8px;
  color: white;
}
.jide-tab.math.active .jide-tab-mid,
.jide-tab.english.active .jide-tab-mid {
  background: hsl(197, 100%, 99%);
  border: 1px solid hsl(197, 100%, 99%);
  color: hsl(208, 14%, 24%);
}
.jide-tab.scratch.active .jide-tab-mid,
.jide-tab.web.active .jide-tab-mid {
  background: hsl(201, 100%, 97%);
  border: 1px solid hsl(201, 100%, 97%);
  color: hsl(208, 14%, 24%);
}
.jide-tab-mid div {
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.jide-tab-mid-text {
  overflow: hidden;
  padding-right: 16px;
  padding-left: 16px;
}
.jide-tab-mid-x {
  justify-content: flex-end;
  color: rgba(255, 255, 255, 0);
}

/* Close tab button styles */

button.jide-close-tab {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  transition: transform 0.2s;
}
button.jide-close-tab .icon {
  background: rgba(0, 0, 0, 0.17);
  border-radius: 50%;
  height: 6px;
  width: 6px;
  padding: 7px;
}
button.jide-close-tab .icon path {
  stroke: rgba(255, 255, 255, 0.5);
  transition: stroke 0.2s;
}
.jide-tab.active button.jide-close-tab .icon {
  background: rgba(255, 255, 255, 0.17);
}
.jide-tab.active button.jide-close-tab .icon path {
  stroke: rgba(255, 255, 255, 0.8);
}
.jide-tab.math.active button.jide-close-tab .icon,
.jide-tab.english.active button.jide-close-tab .icon,
.jide-tab.scratch.active button.jide-close-tab .icon,
.jide-tab.web.active button.jide-close-tab .icon {
  background: hsl(208, 38%, 75%);
}
button.jide-close-tab:hover {
  transform: scale(1.25);
  box-shadow: none;
}
button.jide-close-tab:hover .icon path,
.jide-tab.active button.jide-close-tab:hover .icon path {
  stroke: rgba(255, 255, 255, 1);
}

button.jide-recording-tab {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  transition: transform 0.2s;
  color: #d33838;
}

/* Styles to create slopes on the left and right sides of tabs */

.jide-tab .jide-tab-right,
.jide-tab .jide-tab-left {
  position: relative;
  width: 10px;
}
.jide-tab .jide-tab-right > div,
.jide-tab .jide-tab-left > div {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  bottom: 0;
}
.jide-tab.active .jide-tab-right > div,
.jide-tab.active .jide-tab-left > div,
.jide-tab.active .jide-tab-mid {
    background: #0f3a52;
}
.jide-tab .jide-tab-right > div,
.jide-tab .jide-tab-left > div,
.jide-tab .jide-tab-mid {
    background: #1c6388;
}
.jide-tab .jide-tab-right > div {
    border: 0;
    width: 20px;
    height: 100%;
    position: absolute;
    left: -16px;
    border-right: 1px solid #00314b;
    top: 7px;
    transform: rotate(-12deg);
}
.jide-tab-left, .jide-tab-right {
    overflow: hidden;
}
.jide-tab .jide-tab-left > div {
  border: 0;
  width: 20px;
  height: 100%;
  position: absolute;
  right: -16px;
  border-left: 1px solid #00314b;
  top: 7px;
  transform: rotate(12deg);
}

.jide-tab.math.active .jide-tab-right > div,
.jide-tab.math.active .jide-tab-left > div,
.jide-tab.math.active .jide-tab-mid,
.jide-tab.english.active .jide-tab-right > div,
.jide-tab.english.active .jide-tab-left > div,
.jide-tab.english.active .jide-tab-mid {
    background: white;
}
.jide-tab.math.active .jide-tab-left > div,
.jide-tab.english.active .jide-tab-left > div {
  border-color: transparent transparent hsl(197, 100%, 99%) transparent;
}
.jide-tab.math.active .jide-tab-right > div,
.jide-tab.english.active .jide-tab-right > div {
  border-color: transparent transparent transparent #fff;
}
.jide-tab.scratch.active .jide-tab-left > div,
.jide-tab.web.active .jide-tab-left > div {
  border-color: transparent transparent hsl(201, 100%, 97%) transparent;
}
.jide-tab.scratch.active .jide-tab-right > div,
.jide-tab.web.active .jide-tab-right > div {
  border-color: transparent transparent transparent hsl(201, 100%, 97%);
}

/* Edit mode warning message styles for modalViewMode */

.jide-tb .edit-mode-warning {
  min-height: 0;
  margin-right: 48px;
  align-self: center;
  font-family: 'Gotham', sans-serif;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
}

.jide-tb .edit-mode-warning .icon {
  background: hsl(0, 72%, 49%);
  margin: 0 10px;
  padding: 10px;
}
.jide-tb .edit-mode-warning .icon path {
  fill: #fff;
}
