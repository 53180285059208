.jrs-displaymediarequest {
  width: 700px;
}
.jrs-displaymediarequest-instructions .jrs-modal-subsection .jrs-modal-heading2 {
  flex: 0;
}
.jrs-displaymediarequest-instructions > div {
  flex: 1 1 0px;
}
.jrs-displaymediarequest-instructions > .jrs-modal-gif {
  height: 250px;
}
.jrs-displaymediarequest.jrs-waiting {
  position: absolute;
  padding: 10px;
  top: 0px;
  width: 300px;
  height: auto;
  z-index: 1000;
  border-radius: 12px;
  margin: 1rem;
  background: linear-gradient(45deg, #084361, #016ea7);
  border: 2px solid #237ba9;
  box-shadow: 0px 10px 40px -10px #031e2c;
  color: #cfdee7;
  left: 0;
}
.jrs-displaymediarequest.jrs-waiting > .jrs-modal-section {
  padding: 0px;
  height: 100%;
  flex-direction: column;
}
.jrs-displaymediarequest.jrs-waiting .jrs-waiting-upper {
  margin-right: 0px;
}
.jrs-displaymediarequest.jrs-waiting .jrs-waiting-upper > .jrs-modal-gif {
  width: 100%;
  max-width: 580px;
  align-items: flex-start;
}
/* .jrs-displaymediarequest.jrs-waiting .jrs-waiting-left > .jrs-modal-gif > .fitvid {
  justify-content: flex-end;
} */
.jrs-displaymediarequest.jrs-waiting .jrs-waiting-lower {
  padding-bottom: 10px;
}
.jrs-displaymediarequest.jrs-waiting .jrs-waiting-lower .jrs-modal-heading1 {
  align-self: flex-start;
}

.jrs-displaymediarequest-instructions .sm * {
  color: #8ba1ac;
  font-size: 11px !important;
}
.jrs-displaymediarequest-instructions .jrs-modal-line span {
  flex-direction: column;
  display: flex;
  font-size: 13px;
}

.jrs-displaymediarequest-instructions .jrs-modal-line span:not(.jrs-bold) {
  font-size: 13px;
}

.jrs-displaymediarequest-instructions .jrs-modal-line span.jrs-bold {
  font-weight: 700;
  margin-bottom: 4px;
}
.jrs-modal-subsection.jrs-row.jrs-displaymediarequest-instructions .jrs-modal-line-icon {
    margin-right: .5rem;
}
.jrs-waiting-right .jrs-modal-line > span {
  flex-direction: column;
  display: flex;
}
.jrs-waiting-right .jrs-modal-line > span > :nth-child(2) {
  margin-top: 6px;
  color: #c1cfd7;
}

.jrs-waiting-right * {
  color: white;
  text-align: center;
}

.jrs-waiting-right .jrs-modal-line {
  justify-content: center;
}
