.coppa-direct-notice {
  background: transparent;
}
.coppa-direct-notice h1,
.coppa-direct-notice h2,
.coppa-direct-notice p,
.coppa-direct-notice li {
  color: inherit;
}
.coppa-direct-notice h1 {
  font-size: 1.25em;
}
.coppa-direct-notice h2 {
  font-size: 1.1em;
  text-decoration: underline;
}
.coppa-direct-notice p,
.coppa-direct-notice li {
  line-height: 1.8em;
}
.coppa-direct-notice a {
  text-decoration: underline;
}
