.dropzone {
  background-color: #eff9ff;
  border: 2px dashed #a7cee2;
  padding: 1rem;
}

.input {
  display: none;
}

.inputLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  font-weight: 500;
  color: #335875;
  cursor: pointer;
  min-height: 6rem;
}

.progressBar {
  flex: 1;
  align-self: center;
}

.title {
  font-size: 1.4em;
  font-weight: 500;
  color: #335875;
  text-overflow: ellipsis;
}

.uploadError {
  font-size: 1.4em;
  font-weight: 500;
  color: #753333;
  flex: 1;
}

.imageView {
  position: relative;
  display: inline-block;
  min-width: 12rem;
  min-height: 4rem;
}

.imageView > img {
  width: auto;
  max-height: 12rem;
}

.editPhoto {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
