.video-duration {
  color: hsl(201, 100%, 75%);
  background: hsl(204, 100%, 95%);
  border: 1px solid hsl(203, 100%, 89%);
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.video-duration .icon {
  line-height: 0;
  display: block;
  border-right: 1px solid hsl(203, 100%, 89%);
  height: 9px;
  width: 9px;
  padding: 4px;
}
.video-duration .icon path {
  fill: hsl(201, 100%, 75%);
}
.video-duration .duration-text {
  width: 36px;
  text-align: center;
  font-size: .7rem;
}

@media only screen and (min-width: 720px) {
  .video-duration .icon {
    height: 11px;
    width: 11px;
  }
}
