.card {
  background: #f8fdff;
  border: 1px solid #e0f2ff;
}

.card.whiteCard {
  background: #fff;
}

.card > * {
  padding: 2rem;
}

.card.narrowPadding > * {
  padding: 1rem;
}

.card.noPadding > * {
  padding: 0;
}

.cardHeader > * + *,
.cardFooter > * + *,
.cardContent > * + * {
  margin-top: 1rem;
}

.cardHeader {
  background: #fbfeff;
  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom: 1px solid #e0f2ff;
}

.cardFooter {
  background: #fbfeff;
  border-radius: 0 0 0.25rem 0.25rem;
  border-top: 1px solid #e0f2ff;
}

.addBoxShadow {
  box-shadow: 0px 10px 30px hsl(208, 46%, 78%);
}
