.jide-default-env-content {
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #eef9ff;
}

.jide-default-env-content-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  border-radius: 3px;
  /* border: 30px solid rgba(0, 165, 255, 0.2); */
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 0px 12px rgba(100, 100, 100, 0.15);
}

.jide-default-env-content-top {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  color: #00456b;
  font-size: 18px;
  letter-spacing: 0.03em;
  text-align: center;
  background-color: rgba(0, 141, 218, 0.2);
  padding: 20px;
  box-sizing: border-box;
}

.jide-default-env-content-bottom {
  display: flex;
  width: 100%;
  background-color: white;
  align-items: flex-end;
  justify-content: center;
  padding-top: 20px;
}

.jide-default-env-content img {
  width: 80%;
}
