.jrs-intro {
  width: 500px;
}
.jrs-intro-upper {
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.jrs-intro-lower {
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.jrs-intro-lower > div {
  text-align: center;
}
