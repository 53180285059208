.jide-scratch-env {
  height: 100%;
  width: 100%;
  background: #eef9ff;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  overflow-y: auto;
}
.jide-scratch-env p {
  color: #446785;
}
.jide-scratch-env.loading-state-view {
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
}
.jide-scratch-env .upper-border {
  position: absolute;
  top: 0;
  left: 0;
  right: 12px;
  height: 16px;
  background: #eef9ff;
}
.jide-scratch-env .layout {
  max-width: 730px;
  margin: 0 auto;
  padding: 16px 32px;
}
.jide-scratch-env .layout * {
  letter-spacing: 0;
  line-height: 1.7;
}
.jide-scratch-env .layout h3 {
  font-size: 20px;
  margin: 0;
  border-bottom: 1px solid #def0fc;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}
.jide-scratch-env .sub-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
}
.jide-scratch-env .sub-header .line {
  max-width: 200px;
  flex: 1;
  height: 1px;
  background: #6c9cb5;
}
.jide-scratch-env .sub-header p {
  font-family: "Gotham", sans-serif;
  letter-spacing: 0.06em;
  color: #6c9cb5;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0 14px;
  font-size: 12px;
}
.jide-scratch-env h1,
.jide-scratch-env h2,
.jide-scratch-env h3 {
  color: #00456b;
}
.jide-scratch-env-header {
  text-align: left;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.jide-scratch-env-header h1 {
  flex: 1;
  font-size: 24px;
  margin: 0;
}
.jide-scratch-env-header .header-tag-v2 {
  min-height: 28px;
  padding: 0 12px;
  margin-left: 12px;
}
.jide-scratch-select {
  margin: 0 0 1rem;
}
.jide-scratch-env .scratch-env-options {
  padding-top: 0.25rem;
  display: flex;
  flex-direction: row;
  flex: 1;
}
.jide-scratch-env .scratch-env-option {
  background: #fff;
  margin: 0;
  overflow: hidden;
  text-align: center;
  border: 2px solid #d5f1ff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: 14px;
  box-shadow: 0px 14px 44px 0px hsl(202deg 39% 76% / 38%);
  min-width: calc(100% - 16px);
  box-sizing: border-box;
  border-radius: 0.75rem;
}

.jide-scratch-env .scratch-env-option h2 {
  margin-bottom: 0;
  font-size: 20px;
}
.jide-scratch-env .scratch-buttons-container {
  margin-right: 1.5rem;
  max-width: 330px;
  min-width: 280px;
}
.jide-scratch-env .scratch-buttons-container > :not(:first-child) {
  margin-top: 1rem;
}
.jide-scratch-env .scratch-project-container {
  width: 100%;
}
.jide-scratch-env .scratch-project-container-desktop {
  border-radius: 8px;
  overflow: initial;
  width: 100%;
  max-width: 930px;
}

.jide-scratch-env .scratch-project-container-desktop.playground-project {
  max-width: 1000px;
  margin: 0 auto;
}
.jide-scratch-env .scratch-env-button {
  background: #fff;
  box-shadow: 0px 6.75439px 21.2281px rgba(159, 205, 231, 0.22);
  border: 2px solid #dcf3ff;
  color: #335875;
  font-size: 14px;
  border-radius: 8px;
  margin: 0;
  padding: 12px;
  width: 100%;
  margin: 0;
  letter-spacing: 0;
  text-transform: none;
  text-align: left;
}
.jide-scratch-env .scratch-env-button:first-child {
  margin-top: 0px;
}
.jide-scratch-env .scratch-env-button span {
  margin-right: 20px;
}
.jide-scratch-env .scratch-env-button:hover {
  background: #f0fbff;
}
.jide-scratch-env .scratch-env-button.selected {
  background: #fafdff;
  border: 2px solid #00a5ff;
  font-weight: bold;
  box-shadow: 0px 6.75439px 21.2281px rgba(107, 181, 221, 0.22);
}
.jide-scratch-env .scratch-env-button.selected:hover {
  background: #fff;
}
.jide-scratch-env .scratch-env-option > .icon {
  border: 14px solid hsl(202, 73%, 97%);
  background: hsl(201, 100%, 45%);
  margin: 0 auto;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 30px;
  flex-shrink: 0;
}
.jide-scratch-env .scratch-env-option > .icon path {
  fill: #fff;
  stroke: #fff;
}
.jide-scratch-env .scratch-env-option p,
.jide-scratch-env .jide-scratch-instructions p {
  font-size: 14px;
  letter-spacing: -0.12px;
}

.jide-scratch-env .scratch-env-option .buttons-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 1rem;
}
.jide-scratch-env .scratch-env-link {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 5px;
  margin-bottom: 16px;
  background: #f2faff;
  border: 1px solid #ceecff;
  box-shadow: inset 0px 4px 14px 0px hsl(200, 60%, 92%);
  box-sizing: border-box;
}
.jide-scratch-env .scratch-env-link .link-text {
  padding: 0 0.5rem;
  flex: 1;
  width: 100px;
  font-size: 14px;
  color: #004c75;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.jide-scratch-env .scratch-env-link .link-copy-button {
  background: #fff;
  border: 2px solid #ceecff;
  border-radius: 8px;
  color: hsl(201, 100%, 63%);
  text-transform: uppercase;
  padding: 5px;
  padding-left: 0;
}
.jide-scratch-env .scratch-env-link .link-copy-button span {
  margin: 0 18px;
  line-height: 0;
  font-weight: bold;
  letter-spacing: 0.06em;
}
.jide-scratch-env .scratch-env-link .link-copy-button .icon {
  background: hsl(204, 100%, 95%);
  padding: 5px;
  margin: 0;
}
.jide-scratch-env .scratch-env-link .link-copy-button .icon path {
  fill: hsl(201, 100%, 63%);
}
.jide-scratch-env .scratch-env-action-button {
  display: flex;
  text-align: center;
  text-transform: unset;
  align-items: center;
  color: white;
  padding: 8px;
  padding-left: 24px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: 0.06em;
  border-radius: 6px;
  background: hsl(201, 100%, 50%);
  box-sizing: border-box;
}
.jide-scratch-env .scratch-env-action-button:hover {
  box-shadow: 0 6px 8px 0 hsla(201, 100%, 37%, 0.2);
}
.jide-scratch-env .scratch-env-action-button span {
  flex: 1;
  padding: 0 12px;
}
.jide-scratch-env .scratch-env-action-button .icon {
  background: hsl(201, 100%, 47%);
  border-radius: 6px;
  margin-left: auto;
}
.jide-scratch-env .scratch-env-action-button .icon path {
  fill: #fff;
}
.jide-scratch-env .scratch-env-option small {
  margin: 1rem 0 0;
  flex-shrink: 1;
}

.started-project__container,
.added-project__container h1 {
  padding: 1.25rem;
  margin: 0;
}

.unstarted-scratch-project {
  display: flex;
  text-align: left;
  padding: 1rem 1rem 1.25rem;
  flex-direction: row;
}
.unstarted-scratch-project h3 {
  margin-top: 0px;
}
.unstarted-scratch-video {
  flex: 1 0 42.5%;
  background: #edf6fb;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2f2fb;
  border-radius: 6px;
}
.unstarted-scratch-video video {
  max-width: 475px;
  width: 100%;
  border-radius: 6px;
  object-fit: contain;
  max-height: initial;
}
.unstarted-scratch-link {
  flex: 0 1 auto;
  display: flex;
  margin: 1rem 0 0 0;
  flex-direction: column;
  justify-content: space-between;
}
.unstarted-scratch-desc {
  margin-bottom: 1rem;
}
.unstarted-scratch-link.no-video {
  width: 100%;
  margin-left: 0;
}
.jide-scratch-instructions {
  background: #f5fcff;
  padding: 0.75rem 1.25rem;
  margin: 1rem 0 0 0;
  text-align: left;
  font-size: 14px;
  border: 1px solid #def4ff;
  box-shadow: initial;
  min-width: calc(100% - 16px);
  box-sizing: border-box;
}
.jide-scratch-instructions p {
  padding: 8px 0;
}
.jide-scratch-instructions h3 {
  padding: 0 !important;
  border-bottom: initial !important;
  margin-bottom: 0 !important;
}
@media (min-width: 992px) {
  .unstarted-scratch-link {
    margin: 0 0 0 1.25rem;
  }
  .jide-scratch-env .layout {
    max-width: 1280px;
  }
}
@media (max-width: 992px) {
  .unstarted-scratch-project {
    flex-direction: column;
    align-items: center;
  }
  .unstarted-scratch-video,
  .unstarted-scratch-link {
    width: 100%;
  }
  .unstarted-scratch-project h3 {
    margin-top: 20px;
  }
}

@media (max-width: 720px) {
  .jide-scratch-env-header h1 {
    font-size: 18px;
  }
}
