.ui-editable-date-picker.ui-pop-out {
  position: relative;
}

.ui-editable-date-picker.ui-pop-out .ui-editor {
  position: absolute;
  min-width: 12rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 1rem;
  z-index: 999;
}
