.jide-wrapper-teacher {
  position: relative;
  height: 100%;
  display: flex;
}

.jide-wrapper-teacher .jide-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: auto;
}
