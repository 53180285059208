.ui-layout {
  padding: 1rem;
}

.ui-layout.ui-full-height-layout {
  flex: 1;
  display: flex;
}

.ui-layout-content {
  min-width: 35rem;
}

.ui-layout-content-no-min-width {
  min-width: 0;
}

.ui-layout .ui-full-height-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ui-layout .ui-cap-width {
  width: 100%;
  margin: 0 auto;
}

.ui-layout .ui-cap-width.ui-cap-width-medium {
  max-width: 60rem;
}

.ui-layout .ui-cap-width.ui-cap-width-large {
  max-width: 75rem;
}

.ui-layout .ui-cap-width.ui-cap-width-x-large {
  max-width: 90rem;
}

.ui-layout .ui-cap-width.ui-cap-width-xx-large {
  max-width: 120rem;
}

.ui-layout > .ui-layout-title {
  color: #000;
  font-size: 1.7rem;
}
