/* We need disabled too to override global styles from button... */
.button,
.button:disabled {
  color: #fff;
  font-size: inherit;
  font-weight: bold;
  background-color: hsl(201, 100%, 50%);
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border: 2px solid rgba(255, 255, 255, 0.15);
  letter-spacing: 0.05em;
  transition: 0.1s;
  text-transform: none;
  font-family: inherit;
  line-height: 1.5rem;
}

.button.withIcon {
  padding-right: 4.5rem;
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  right: 0.5em;
  transform: translateY(-50%);
  height: 1.5em;
  width: 1.5em;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button.secondary {
  background-color: hsl(201, 100%, 21%);
}

.button.tertiary {
  background-color: #fff;
  color: #00a5ff;
  border: 1px solid #d1eaf7;
}

.button.tertiary .icon {
  background: rgba(0, 165, 255, 0.15);
}

.button:disabled {
  opacity: 0.7;
}

.button.small {
  padding: 0.3rem 0.5rem;
  font-size: 0.7em;
  line-height: 1.2;
  border-radius: 0.1em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.button.link {
  background-color: transparent;
  color: #00a5ff;
  padding: 0;
  box-shadow: none;
  border: none;
  text-transform: uppercase;
}

.button.link.withIcon {
  padding-right: 2rem;
}

.button.small.link {
  font-size: 0.9em;
}

.button:hover:not(:disabled) {
  background-color: hsl(201, 100%, 60%);
}

.button.secondary:hover:not(:disabled) {
  background-color: hsl(201, 100%, 31%);
}

.button.tertiary:hover:not(:disabled) {
  background: #fff;
  box-shadow: 0px 4px 6px rgba(11, 45, 64, 0.08);
}

.button.tertiary:active,
.button.tertiary:active:hover,
.button.tertiary.active {
  border: 1px solid #00a5ff;
  box-shadow: 0px 0px 2px #00a5ff;
}

.button.tertiary:active:hover:not(:disabled),
.button.tertiary.active:hover:not(:disabled) {
  box-shadow: 0px 0px 3px #00a5ff;
}

.button.link:hover:not(:disabled) {
  background-color: transparent;
  color: hsl(201, 100%, 60%);
  text-decoration: underline;
}
