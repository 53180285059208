.status-icon.icon {
  height: 8px;
  width: 8px;
  padding: 4px;
  border-radius: 50%;
  background: hsl(201, 77%, 35%);
  flex-shrink: 0;
  box-sizing: content-box;
}
#jide-nb div.status-icon.icon {
  box-sizing: content-box;
}
.status-icon.icon polyline {
  stroke: #fff;
}
.status-icon.icon path,
.status-icon.icon rect {
  fill: #fff;
}
.status-icon.completed.icon {
  background: hsl(128, 68%, 53%);
}
