.tooltip {
  position: relative;
}

.tooltip .tooltipContent {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 10px 40px -10px hsl(208, 46%, 78%);
  padding: 1em;
  width: 200px;
  z-index: 999;
}

.tooltip .tooltipContent.right {
  top: 0;
  left: 100%;
}

.tooltip .tooltipContent.below {
  top: 100%;
  left: 0;
}

.tooltip:hover .tooltipContent {
  display: block;
}
