/* Awkward CSS rule to make am/pm fit */
.timePicker :global div.react-datepicker__time-box {
  width: 100px !important;
}

.timePicker :global .react-datepicker__time-container {
  width: 100px;
}

.fluidWidth :global .react-datepicker-wrapper {
  width: 100%;
}

.fluidWidth :global .react-datepicker__input-container {
  width: 100%;
}
