.message {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 2px solid rgba(255, 255, 255.75);
  font-weight: bold;
}

.message.success {
  background-color: #e8ffe6;
  color: rgb(16, 63, 13);
}

.message.warning {
  background-color: #fff5e2;
  color: rgb(88, 65, 20);
}

.message.error {
  background-color: #ffeded;
  color: rgb(187, 34, 34);
}

.message.info {
  background-color: #f5f5f5;
  color: #6e6e6e;
}

.message.withIcon {
  padding-left: 3.5rem;
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.5rem;
  border-radius: 0.5rem;
  height: 2rem;
  width: 2rem;
  box-sizing: border-box;
  padding: 0.5rem;
}

.message.success .icon {
  background-color: rgba(16, 63, 13, 0.05);
}

.message.warning .icon {
  background-color: rgba(88, 65, 20, 0.05);
}

.message.error .icon {
  background-color: rgba(187, 34, 34, 0.05);
}
