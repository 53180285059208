button.action-button {
  background-color: hsl(208, 100%, 53%);
  margin-top: 0;
  padding: 4px 24px;
  font-family: 'Gotham', sans-serif;
  text-align: center;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  flex-wrap: nowrap;
  font-size: 12px;
  line-height: 1.8em;
  text-rendering: optimizeLegibility;
  font-weight: 800;
  letter-spacing: 1.5px;
  border-radius: 2px;
}

button.action-button.outline {
  border: 1px solid #9ce0ff;
  color: hsl(208, 100%, 53%);
  background: transparent;
}

button.action-button.with-icon {
  padding-right: 8px;
}
button.action-button.with-icon.icon-left {
  padding-right: 24px;
  padding-left: 8px;
}
button.action-button.with-icon .icon {
  margin-left: 16px;
  float: right;
  flex-shrink: 0;
}
button.action-button.with-icon.icon-left .icon {
  margin-left: 0;
  margin-right: 16px;
  float: left;
}
button.action-button:disabled {
  background: #efefef;
  color: #fff;
  cursor: default;
}
button.action-button:disabled:hover {
  box-shadow: none;
}
button.action-button:disabled .icon,
button.action-button:disabled .icon.icon-lightblue {
  background: #e8e8e8;
}
