.learner-app {
  color: hsl(208, 9%, 34%);
}

.learner-app p {
  line-height: 1.8em;
  margin: 0;
}

.learner-app .layout-content {
  max-width: 1280px;
  margin: 0 auto;
}
.learner-app .bg-graphic {
  position: absolute;
  pointer-events: none;
}
