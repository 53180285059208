.jide-eb {
  display: flex;
  flex: 1;
  position: relative;
}

.jide-environment-container {
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.jide-environment-container {
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.jide-environment-container:not(.jide-environment-container-active) {
  z-index: 0;
}
.jide-environment-container:not(.jide-environment-container-active)
  .jide-scratch-env {
  position: absolute;
  left: -9999em;
  top: -9999em;
}

.jide-environment-container.jide-environment-container-active {
  z-index: 3;
}
.jide-environment-container.jide-environment-container-active.jide-environment-container-fullscreen {
  z-index: 1001 !important;
  background-color: hsl(201, 69%, 19%);
}
