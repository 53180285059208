.ui-sortable-list {
  margin-top: 1rem;
  border: 1px solid #ccc;
  background-color: #eee;
}

.ui-sortable-list-item {
  padding: 1em;
  background-color: #fff;
  cursor: move;
}

.ui-sortable-list-item.ui-dragging {
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px #aaa;
}

.ui-sortable-list .ui-sortable-list-item {
  border: none;
  border-top: 1px solid #ccc;
}

.ui-sortable-list .ui-sortable-list-item:first-of-type {
  border-top: none;
}
