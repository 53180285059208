.ui-checked-field {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ui-checked-field .ui-checked-label {
  margin-left: 0.5rem;
  margin-top: 0;
}

.ui-checked-field.ui-disabled .ui-checked-label {
  color: #999;
}
