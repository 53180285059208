.jrs-viewrecordings {
  width: 750px;
  min-height: 500px;
  max-height: 650px;
}

.jrs-modal-viewrecordings-header {
  flex-shrink: 0;
}
.jrs-modal-recordings {
  flex-grow: 1;
  min-height: 0px;
}
.jrs-modal-previews {
  background: #e9f7fd;
  padding: 14px 10px;
  flex-shrink: 0;
  width: 160px;
  border-right: 2px solid #def4fd;
}
.jrs-modal-previewsheading {
  flex: 0 0 0px;
  padding-left: 2px;
  margin-bottom: 5px !important;
}
.jrs-modal-previewslist {
  flex-grow: 1;
  min-height: 0px;
}
.jrs-modal-previewslist-content-wrapper {
  flex-grow: 1;
  overflow-y: auto;
}
.jrs-modal-subsection.jrs-column.jrs-modal-previewslist {
    margin-top: 8px;
}
/* .jrs-modal-previewslist-content {
} */
.jrs-modal-feature {
  flex-grow: 1;
  padding: 24px;
}
.jrs-modal-previews .jrs-modal-nextbutton.jrs-active {
  flex: 0;
  font-size: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.jrs-modal-previews .jrs-modal-nextbutton-content {
  margin-right: 5px;
}
.jrs-modal-previews .jrs-modal-nextbutton-arrow {
  padding: 0;
  height: 16px;
  width: 16px;
  font-size: 12px;
}

.jrs-modal-preview {
  --jrs-blue: #00a5ff;

  flex: 0 0 auto;
  cursor: pointer;
  flex-direction: column;
  border-radius: 8px;
  border: 2px solid #98d9ff;
  overflow: hidden;
}
.jrs-modal-previews {
    padding: 14px 14px;
    width: 170px;
}
.jrs-modal-preview-upper {
  position: relative;
  border-bottom: 2px solid #98d9ff;
  background-color: #98d9ff;
}
.jrs-modal-preview-lower {
  background-color: white;
  flex-direction: column;
  padding: 4px 0 8px 6px;
  justify-content: center;
  flex: 1;
}
.jrs-modal-preview-length {
  position: absolute;
  bottom: 3px;
  right: 3px;
  padding: 0px 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;
  box-shadow: 0px 0px 5px 0px rgba(103, 202, 255, 0.7);
  border-radius: 3px;
  background-color: white;
  color: #3c6176;
}
.jrs-modal-preview-title {
  font-size: 13px;
  font-weight: 600;
  color: #3c6176;
}
.jrs-modal-preview-createdat {
  font-size: 11px;
  color: rgba(60, 97, 118, 0.5);
}
.jrs-modal-preview:hover {
  border: 2px solid #67c9ff;
  box-shadow: 0px 0px 6px 0px rgba(103, 202, 255, 0.7);
}
.jrs-modal-preview:hover .jrs-modal-preview-upper {
  border-bottom: 2px solid #67c9ff;
  background-color: #67c9ff;
}
.jrs-modal-preview.jrs-selected {
  border: 2px solid #2db6ff;
  box-shadow: 0px 0px 6px 0px rgba(0, 166, 255, 0.7);
}
.jrs-modal-preview.jrs-selected .jrs-modal-preview-upper {
  border-bottom: 2px solid #2db6ff;
  background-color: #2db6ff;
}
.jrs-modal-preview.jrs-selected .jrs-modal-preview-lower {
  background-color: #2db6ff;
}
.jrs-modal-preview.jrs-selected .jrs-modal-preview-title {
  color: white;
}
.jrs-modal-preview.jrs-selected .jrs-modal-preview-createdat {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
}
.jideshare-sp .jrs-modal-preview-upper {
  max-height: 105px;
}

.jrs-modal-preview.jrs-modal-createnewbtn {
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: #98d9ff;
}
.jrs-modal-preview.jrs-modal-createnewbtn:hover {
  color: #67c9ff;
}
.jrs-modal-preview.jrs-modal-createnewbtn.jrs-selected {
  background-color: var(--jrs-blue);
  color: white;
}

.jrs-modal-selectedvideo {
  flex-grow: 1;
}
.jrs-modal-selectedvideo > .jrs-modal-video {
  border: 2px solid rgba(206, 237, 255, 1);
  background-color: rgba(206, 237, 255, 0.2);
  box-shadow: 0px 0px 6px 0px rgba(206, 237, 255, 0.6);
  max-height: 350px;
}
.jrs-modal-selectedvideo .jrs-modal-heading3 {
  margin-bottom: 3px !important;
}
.jrs-modal-selectedvideo .jrs-modal-selectedvideo-metadata > .jrs-modal-subsection {
  margin-right: 30px;
}
.jrs-modal-selectedvideo .jrs-modal-input {
  flex-grow: 1;
}
.jrs-modal-selectedvideo .jrs-modal-titlebutton {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  border-radius: 5px;
  padding: 0px 8px;
  margin-right: 10px;
  box-shadow: 0px 0px 4px 0px rgba(50, 82, 101, 0.2);
  height: 22px;
}
.jrs-modal-selectedvideo .jrs-modal-titlebutton:hover {
  box-shadow: 0px 0px 4px 0px rgba(50, 82, 101, 0.5);
}
.jrs-modal-selectedvideo .jrs-modal-titlebutton {
    border: 1px solid;
    height: 28px;
    width: 28px;
    padding: 0;
    border: 2px solid;
}
.jrs-modal-titlebutton.jrs-modal-edittitle {
  color: rgba(0, 166, 255, 0.5);
}
.jrs-modal-titlebutton.jrs-modal-deletevideo {
  color: rgba(211, 56, 56, 0.5);
}

.jrs-deletevideo-splash {
  flex-grow: 1;
  border-radius: 10px;
  background-color: #fff3f3;
  align-items: center;
  justify-content: center;
  color: var(--jrs-red);
  font-weight: 600;
}
.jrs-deletevideo-splash-icon {
  font-size: 40px;
}
.jrs-deletevideo-splash-message {
  font-size: 20px;
}
.jrs-deletevideo-splash-text {
  font-size: 13px;
}
.jrs-deletevideo-splash-btns {
  width: 100%;
  padding: 0px 10px;
}

.jrs-modal-createnew {
  flex-grow: 1;
}
.jrs-modal-createnew .jrs-modal-line-icon {
    margin-right: .5rem;
    justify-content: center;
}
.jrs-modal-createnew .jrs-modal-line-icon svg * {
    fill: #86b6cf;
}
.jrs-modal-createnew .jrs-modal-subsection.jrs-column {
  margin-bottom: 12px;
}
.jrs-modal-createnew .jrs-modal-subsection.jrs-column:last-child {
  margin-top: auto;
  margin-bottom: 0px;
}
.jrs-modal-createnew .jrs-modal-subsection.jrs-column img {
  width: 100%;
}
.jrs-modal-createnew .jrs-modal-subsection.jrs-column.jrs-cta .jrs-bold {
    margin-right: .5rem;
}
.jrs-viewrecordings .jrs-modal-cards {
  margin-top: 20px;
}
.jrs-viewrecordings .jrs-modal-cards {
    margin-top: 8px;
}
.jrs-saverec .jrs-column:not(.jrs-section) {
    padding: 14px;
}