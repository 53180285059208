.alert-banner {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: hsl(208, 94%, 13%);
  color: white;
  transition: all 0.2s;
}

.alert-banner > .icon-container {
  background: hsl(208, 85%, 16%);
  padding: 0;
  height: 100%;
  position: absolute;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-banner.alert-banner-warning {
  background: hsl(58, 94%, 13%);
}
.alert-banner.alert-banner-warning > .icon-container {
  background: hsl(58, 85%, 16%);
}

div.alert-banner-content {
  margin-left: 52px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
}
.alert-banner-content .alert-banner-text {
  line-height: 1.4em;
  align-self: flex-start;
  font-size: 12px;
}
.alert-banner-content.with-button .alert-banner-text {
  margin-bottom: 16px;
}

.alert-banner.session-alert {
  margin-bottom: 36px;
}

@media only screen and (min-width: 340px) {
  .alert-banner > .icon-container {
    width: 52px;
  }
  div.alert-banner-content {
    margin-left: 52px;
  }
  .alert-banner-content .alert-banner-text {
    font-size: 13px;
  }
}

@media only screen and (min-width: 560px) {
  .alert-banner {
    min-height: 60px;
  }
  .alert-banner > .icon-container {
    width: 60px;
  }
  div.alert-banner-content {
    flex-direction: row;
    align-items: center;
    padding: 8px 8px 8px 16px;
    margin-left: 60px;
  }
  .alert-banner-content .alert-banner-text {
    font-size: 14px;
    align-self: auto;
  }
  .alert-banner-content.with-button .alert-banner-text {
    margin-right: 28px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 900px) {
  .alert-banner.session-alert .alert-banner-content .alert-banner-text {
    font-size: 16px;
  }
}
