.jco {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  box-sizing: border-box;
  align-items: stretch;
}

.jco-bar {
  display: flex;
  flex-direction: row;
  align-content: center;
  width: 100%;
  height: 50px;
  background-color: #0f3a52;
  justify-content: space-between;
  box-sizing: border-box;
}
.jco-bar-left {
  display: flex;
  height: 100%;
  align-content: center;
}

.jco-bar-right {
  display: flex;
  height: 100%;
}
.jco-tab {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Gotham', sans-serif;
}
.jco-tab.jco-hide {
  visibility: hidden;
}
.jco-tab .jco-tab-upper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding-top: 16px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}
.jco-tab .jco-tab-lower {
  display: flex;
  box-sizing: border-box;
  padding-top: 6px;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  flex-direction: row;
  height: 16px;
  width: 100%;
}
.jco-tab .jco-iconBox {
  display: flex;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  border-radius: 3px;
}
.jco-tab.jco-active {
  /* border-top: 4px solid white; */
  /* background-color: rgba(255, 255, 255, 0.05); */
}
.jco-tab.jco-active .jco-tab-upper {
  font-weight: 600;
  color: white;
}
.jco-tab.jco-active .jco-tab-lower {
  color: rgba(255, 255, 255, 1);
}
.jco-tab.jco-active .jco-iconBox {
  color: #0f3a52;
  background-color: white;
}
.jco-tab.jco-inactive {
  /* border-top: 4px solid transparent; */
}
.jco-tab.jco-inactive .jco-tab-upper {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}
.jco-tab.jco-inactive .jco-tab-lower {
  color: transparent;
}
.jco-tab.jco-inactive .jco-iconBox {
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.6);
}
.jco-tab.jco-inactive:hover {
  /* border-top: 4px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.01); */
}
.jco-tab.jco-inactive:hover .jco-tab-upper {
  color: white;
}
.jco-tab.jco-inactive:hover .jco-tab-lower {
  color: rgba(255, 255, 255, 0.3);
}
.jco-tab.jco-inactive:hover .jco-iconBox {
  color: white;
}
/* .jco-iconBox.jco-errorBox {
  color: white;
  background-color: #d52224;
} */

.jco-main {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  flex: 1;
  box-sizing: border-box;
  z-index: 0;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
}
.jco-elements-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  box-sizing: border-box;
}
.jco-element {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  padding: 30px;
  box-sizing: border-box;
}
.jco-element.jco-active {
  z-index: 1;
}
.jco-element.jco-inactive {
  z-index: 0;
}
.jco-console-output {
  margin: 0px;
  background-color: white;
  color: #002b36;
  font-size: 14px;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro',
    monospace;
  overflow: auto;
  box-sizing: border-box;
  padding: 0px;
}
.jco .xterm {
  padding: 30px;
}
.jco-instructions-pane {
  margin: 0px;
  background-color: white;
  color: #002b36;
  font-size: 14px;
  overflow-x: auto;
  overflow-y: scroll;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}
.jco-instructions-pane .header-tag-v2 {
  min-height: 28px;
  float: right;
  padding: 0 12px;
}
.jco-instructions-pane-content {
  height: 100%;
  width: 100%;
}
.jco-instructions-pane-content img {
  width: 100%;
}
.jco-instructions-pane-content video {
  width: 100%;
}
.jco-instructions-pane-content > *:first-child {
  margin-top: 0px;
}
.jco-instructions-pane-content.jco-supplemental > *:first-child {
  margin-right: 119px;
}
.jco-instructions-pane-content.jco-supplemental.easy > *:first-child {
  margin-right: 89px;
}
.jco-instructions-pane-content.jco-supplemental.medium > *:first-child {
  margin-right: 85px;
}
