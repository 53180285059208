.layout-container {
  margin: 0px;
  padding: 36px 16px;
  align-self: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.layout-container.take-full-height {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex: 1;
}

.layout-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.layout-container.take-full-height .layout-content {
  flex: 1;
}

@media only screen and (min-width: 480px) {
  .layout-container {
    padding: 36px 25px;
  }
}

@media only screen and (min-width: 720px) {
  .layout-container {
    padding: 36px 36px;
  }
}

@media only screen and (min-width: 1500px) {
  .layout-container {
    padding: 36px;
  }
  .layout-content {
    max-width: 1400px;
    margin: 0 auto;
  }
  .layout-container.take-full-height .layout-content {
    width: 100%;
  }
}
