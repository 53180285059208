.home-login-link-container {
  width: 50%;
  display: inline-block;
  text-align: center;
  margin-top: 100px;
}

.home-login-link {
  background: #fff;
  padding: 25px;
  width: 70%;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-weight: 300;
  transition: 0.2s;
  vertical-align: middle;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.home-login-link:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
}

.home-login-learner img {
  height: 187px;
}

.home-login-instructor img {
  margin: 26px 0px;
  width: 280px;
}

.home-login-link-title {
  font-size: 28px;
  margin-bottom: 10px;
  color: #003957;
  font-weight: 600;
}

.home-login-link button {
  margin-top: 5px;
  padding: 15px 25px;
  border-radius: 25px;
  background-color: hsl(208, 100%, 53%);
  box-shadow: 0px 10px 30px -10px #00a5ff;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 300;
}

@media only screen and (max-width: 965px) {
  .home-login-link-container {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
