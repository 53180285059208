.fitvid {
  display: flex;
  justify-content: center;
}
.fitvid div {
  display: flex;
}
.fitvid > video {
  max-width: 100%;
  max-height: 500px;
  border-radius: 5px;
}
.fitvid.fitvid-noBR > video {
  border-radius: 0px;
}
