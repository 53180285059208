.icon {
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
  padding: 8px;
}

.icon > svg {
  width: inherit;
  height: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.icon.icon-small {
  width: 8px;
  height: 8px;
  padding: 2px;
}
.icon.icon-small > svg {
  width: 8px;
  height: 8px;
}

.icon.icon-medium {
  width: 14px;
  height: 14px;
}

.icon.icon-large {
  width: 20px;
  height: 20px;
}

.icon.icon-huge {
  width: 160px;
  height: 160px;
  border-radius: 160px;
  margin-bottom: 24px;
}
.icon.icon-huge > svg {
  width: 96px;
  height: 96px;
}

.icon.icon-xlarge {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  margin-bottom: 24px;
}
.icon.icon-xlarge > svg {
  width: 40px;
  height: 40px;
}

.icon-white {
  background: white;
  border: 1px solid hsl(208, 36%, 87%);
}

.icon-red {
  background: hsl(0, 72%, 49%);
}

.icon-green {
  background: rgb(32, 177, 3);
}

.icon-blue {
  background: hsl(208, 100%, 53%);
}

.icon-lightblue {
  background: hsl(208, 100%, 62%);
}
.icon-remove.icon-lightblue {
  background: rgba(0, 165, 255, 0.15);
}

.icon-lightblue polyline {
  stroke: white !important;
}

.icon-lightblue-bg {
  background: hsl(208, 100%, 62%);
}

.icon-lighterblue {
  background: rgb(241, 249, 255);
}

.icon-midblue {
  background: hsl(208, 100%, 92%);
}

.icon-green-line polyline {
  stroke: rgb(31, 191, 94);
}

.icon-blue-line path,
.icon-blue-line rect {
  fill: hsl(208, 100%, 53%);
}

.icon-red-line polyline {
  stroke: hsl(0, 72%, 49%);
}

.icon-red-line path {
  stroke: hsl(0, 72%, 49%);
}
.icon-red-fill path {
  fill: hsl(0, 72%, 49%);
}

.icon-blue-line polyline {
  stroke: hsl(208, 100%, 53%);
}

.icon-blue-line-alt path {
  stroke: hsl(208, 100%, 53%);
}

.icon-white-line path {
  fill: white;
}

.icon-white-stroke path {
  stroke: white;
}

.icon-white-line polyline {
  stroke: white;
}

.icon-blue-border {
  border: 4px solid hsl(208, 100%, 53%);
}

.icon.chevron polyline {
  stroke: hsl(208, 100%, 53%);
}

.icon.chevron.juni-blue-line polyline {
  stroke: hsl(201, 100%, 50%);
}

.icon.chevron.blue-gray-line polyline {
  stroke: hsl(201, 24%, 40%);
}

.icon.arrow-icon.right {
  transform: rotate(180deg);
}

.icon.arrow-icon.up {
  transform: rotate(90deg);
}

.icon.arrow-icon.down {
  transform: rotate(270deg);
}

.icon.wide-arrow-icon.left {
  transform: rotate(90deg);
}

.icon.wide-arrow-icon.up {
  transform: rotate(180deg);
}

.icon.wide-arrow-icon.right {
  transform: rotate(270deg);
}

.icon.chevron.right,
.icon.caret-icon.right,
.icon.tall-chevron.down {
  transform: rotate(270deg);
}

.icon.chevron.left,
.icon.caret-icon.left,
.icon.tall-chevron.up {
  transform: rotate(90deg);
}

.icon.chevron.flipped,
.icon.caret-icon.flipped,
.icon.tall-chevron.left {
  transform: rotate(180deg);
}

.icon.icon-logo {
  width: 65px;
  height: auto;
  display: flex;
}

.icon.icon-logo.logo-large {
  width: 118px;
  height: 50px;
}

.icon-logo path,
.icon-logo rect,
.icon-logo-mini path,
.icon-logo-mini rect {
  fill: white;
}

.icon.icon-logo-mini {
  width: 36px;
  height: 36px;
  background: transparent;
}

.logo-blue path,
.logo-blue rect {
  fill: hsl(208, 100%, 53%);
}
