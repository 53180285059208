.juni-spinner-wrapper {
  display: flex;
  flex: 1;
  align-items: center !important;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
}

.juni-spinner-circles-container div {
  position: absolute;
  border-radius: 50%;
  top: 60px;
  left: 20px;
}

.juni-spinner-circles-container div:nth-child(1) {
  background: #6255B0;
  animation: orbit-1 1s linear infinite;
  animation-delay: -0.5s;
}
.juni-spinner-circles-container div:nth-child(2) {
  background: #292563;
  animation: orbit-1 1s linear infinite;
  animation-delay: 0s;
}
.juni-spinner-circles-container div:nth-child(3) {
  background: #6255B0;
  animation: orbit-2 1s linear infinite;
  animation-delay: -0.5s;
}
.juni-spinner {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.juni-spinner-circles-container {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.juni-spinner-circles-container div { box-sizing: content-box; }

@keyframes orbit-2 {
  0%    { opacity: 1; transform: translate(0 0) }
 49.99% { opacity: 1; transform: translate(80%,0) }
 50%    { opacity: 0; transform: translate(80%,0) }
100%    { opacity: 0; transform: translate(0,0) }
}
@keyframes orbit-1 {
  0% { transform: translate(0,0) }
 50% { transform: translate(80%,0) }
100% { transform: translate(0,0) }
}
