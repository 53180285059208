.repl {
  display: flex;
  overflow: hidden;
  background-color: transparent;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.repl.repl-horizontal {
  flex-direction: row;
}
.repl.repl-vertical {
  flex-direction: column;
}

.repl.repl-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 10px 10vmax rgba(0, 0, 0, 0.75);
  animation: grow 0.25s ease-out;
}
@keyframes grow {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

.repl-sidebar-and-ce {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}
