.jrs-bar {
  position: relative;
  display: none !important;
  height: 50px;
  background-color: var(--jrs-blue);
  flex-grow: 1;
  z-index: 1002;
  justify-content: center;
}
.jrs-bar.jrs-red {
  background-color: var(--jrs-red);
}
.jrs-bar.jrs-expanded {
  display: flex !important;
}
.jrs-bar-button {
  cursor: pointer;
  height: 35px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  opacity: 0.9;
}
.jrs-bar-button:hover {
  opacity: 1;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.jrs-bar-button-left {
  align-items: center;
  margin-right: 10px;
  font-size: 15px;
}
.jrs-bar-button-right {
  align-items: center;
}
.jrs-bar-button.jrs-cancel {
  margin-right: 15px;
  background-color: #325265;
  border: 2px solid #608ea9;
}

.jrs-bar-button.jrs-start {
  margin-right: 15px;
  background-color: #981313;
  border: 2px solid #ff4444;
  animation: fadeInJrsStart ease 4s;
  -webkit-animation: fadeInJrsStart ease 4s;
  -moz-animation: fadeInJrsStart ease 4s;
}
@keyframes fadeInJrsStart {
  50% {
    border-color: #ebc3c3;
    background-color: white;
    color: #981313;
  }
}
@-moz-keyframes fadeInJrsStart {
  50% {
    border-color: #ebc3c3;
    background-color: white;
    color: #ff4444;
  }
}
@-webkit-keyframes fadeInJrsStart {
  50% {
    border-color: #ebc3c3;
    background-color: white;
    color: #ff4444;
  }
}

.jrs-bar-button.jrs-stop {
  position: absolute;
  right: 15px;
  background-color: #981313;
  border: 2px solid #ff4444;
}
.jrs-bar-button.jrs-duration {
  cursor: initial;
  font-size: 16px;
  margin-right: 0px;
}
.jrs-bar-button.jrs-duration:hover {
  opacity: 0.9;
  box-shadow: none;
}
.jrs-bar-button.jrs-maxduration {
  cursor: initial;
  font-size: 14px;
  padding-left: 0px;
  margin-right: 10px;
  opacity: 0.5;
}
.jrs-bar-button.jrs-maxduration:hover {
  opacity: 0.5;
  box-shadow: none;
}

.jrs-uservideo-container {
  position: absolute;
  z-index: 3;
  bottom: 15px;
  left: 15px;
  height: 200px;
  width: 200px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 3px solid var(--jrs-blue);
  /* box-shadow: 0px 0px 4px 3px var(--jrs-blue); */
  background-color: var(--jrs-blue);
  transition: 0.5s;
}
.jrs-uservideo-container:hover {
  opacity: 0.3;
}
.jrs-red .jrs-uservideo-container {
  background-color: var(--jrs-red);
  border: 3px solid var(--jrs-red);
  bottom: 65px;
}
.jrs-uservideo-container video {
  height: 100%;
}
.jrs-uservideo {
  position: absolute;
  z-index: 1002;
}
.jrs-uservideo-background {
  position: absolute;
  z-index: 5;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.jrs-uservideo-background img {
  height: 90%;
  width: 90%;
}

.jrs-options {
  position: absolute;
  align-items: center;
  left: 230px;
  bottom: 7px;
  z-index: 5;
  transition: 0.6s;
}
.jrs-red .jrs-options {
  left: 40px;
}

.jrs-bar-logo {
  height: 100%;
  align-items: center;
}

.jrs-controls {
  position: absolute;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  z-index: 4;
}

@media only screen and (max-height: 1100px) {
  .jrs-uservideo-container {
    height: 180px;
    width: 180px;
    border-radius: 90px;
  }
  .jrs-options {
    margin-left: 210px;
  }
}
@media only screen and (max-height: 900px) {
  .jrs-uservideo-container {
    height: 150px;
    width: 150px;
    border-radius: 75px;
  }
  .jrs-options {
    margin-left: 180px;
  }
}
@media only screen and (max-height: 600px) {
  .jrs-uservideo-container {
    height: 110px;
    width: 110px;
    border-radius: 55px;
  }
  .jrs-options {
    margin-left: 140px;
  }
}

.jrs-selector {
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.jrs-selector-button {
  cursor: pointer;
  height: 35px;
  width: 35px;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  margin-right: 15px;
  opacity: 0.85;
}
.jrs-selector-button.jrs-selected {
  color: var(--jrs-blue);
  background-color: white;
}
.jrs-selector-button.jrs-enabled:hover {
  opacity: 1;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.jrs-selector-button.jrs-disabled {
  display: none;
}
.jrs-selector-devicelist {
  flex-direction: column;
  position: absolute;
  z-index: 6;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 2px solid var(--jrs-blue);
  border-radius: 5px;
  color: var(--jrs-modal-text);
  font-size: 14px;
  width: 300px;
}
.jrs-selector-0 .jrs-selector-devicelist {
  bottom: 60px;
}
.jrs-selector-1 .jrs-selector-devicelist {
  bottom: 60px;
}
.jrs-selector-deviceheader {
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
}
.jrs-selector-deviceheader-left {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.jrs-selector-deviceheader-right {
  flex-grow: 1;
  font-size: 14px;
  align-items: center;
  font-weight: 800;
}
.jrs-selector-device {
  cursor: pointer;
  padding: 10px;
  font-size: 12px;
}
.jrs-selector-device:hover {
  padding: 10px;
  background-color: rgba(0, 166, 255, 0.1);
}
.jrs-selector-device.jrs-selected {
  font-weight: 600;
  color: var(--jrs-blue);
  background-color: rgba(0, 166, 255, 0.3);
}
.jrs-selector-device.jrs-unusable {
  color: #b60000;
}
