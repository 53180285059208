.jrs-modal-line {
  font-size: 14px;
}
.jrs-modal-line-icon {
  flex-shrink: 0;
  align-items: flex-start;
  padding-top: 3px;
  padding-right: 10px;
  width: 30px;
  font-size: 16px;
}
.jrs-modal-line span {
  padding-right: 4px;
}
.jrs-modal-line span.jrs-bold {
  font-weight: 600;
}

.jrs-modal-cards {
  cursor: pointer;
  justify-content: space-between;
}
.jrs-modal-card {
  flex-direction: column;
  height: 90px;
  flex: 1 1 0px;
  border-radius: 4px;
  background: white;
  border: 2px solid #cbecff;
  opacity: 0.8;
  margin-right: 18px;
}
.jrs-modal-card:last-child {
  margin-right: 0px;
}
.jrs-modal-card-lower {
  color: #8daec0;
  padding: 6px 0 8px;
}
.jrs-modal-card.jrs-green {
  background-color: rgba(222, 248, 219, 0.67);
  border: 2px solid rgba(53, 158, 45, 0.5);
}
.jrs-modal-card.jrs-active {
  border: 3px solid var(--jrs-blue);
  box-shadow: 0px 0px 8px 0px rgba(0, 166, 255, 0.4);
  opacity: 1;
}
.jrs-modal-card-upper {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  border-bottom: 2px solid #d3efff;
}
.jrs-green .jrs-modal-card-upper {
  border-bottom: 1px solid rgba(52, 158, 45, 0.15);
}
.jrs-modal-card-lower {
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.jrs-modal-backbutton {
  flex-grow: 1;
  cursor: not-allowed;
  align-items: center;
  background-color: white;
  border: 2px solid var(--jrs-blue);
  border-radius: 4px;
  padding: 5px;
  color: var(--jrs-blue);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  opacity: 0.25;
}
.jrs-modal-backbutton:hover {
  background-color: var(--jrs-blue);
  color: white;
  border: 2px solid transparent;
}
.jrs-modal-backbutton.jrs-red:hover {
  background-color: var(--jrs-red);
  color: white;
  border: 2px solid transparent;
}
.jrs-modal-backbutton.jrs-active {
  cursor: pointer;
  opacity: 1;
}
.jrs-modal-backbutton-content {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.jrs-modal-backbutton-arrow {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
  height: 26px;
  width: 26px;
  background-color: rgba(0, 166, 255, 0.2);
}
.jrs-modal-backbutton:hover .jrs-modal-backbutton-arrow {
  background-color: rgba(255, 255, 255, 0.2);
}

.jrs-modal-nextbutton {
  flex-grow: 1;
  cursor: not-allowed;
  align-items: center;
  background-color: var(--jrs-blue);
  border-radius: 4px;
  padding: 5px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  opacity: 0.25;
}
.jrs-modal-nextbutton.jrs-red {
  background-color: var(--jrs-red);
}
.jrs-modal-nextbutton.jrs-active {
  cursor: pointer;
  opacity: 1;
}
.jrs-modal-nextbutton.jrs-active:hover {
  background-color: #23b2ff;
}
.jrs-modal-nextbutton.jrs-active.jrs-red:hover {
  background-color: #db7171;
}
.jrs-modal-nextbutton-content {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  letter-spacing: 0;
}
.jrs-modal-nextbutton-arrow {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
  height: 26px;
  width: 26px;
  background-color: rgba(255, 255, 255, 0.15);
}
.jrs-modal-nextbutton.jrs-link {
  flex-grow: 0;
  cursor: inherit;
  display: block;
  text-align: center;
  text-decoration: underline;
  background-color: transparent !important;
  color: var(--jrs-blue);
}
.jrs-modal-nextbutton.jrs-link .jrs-modal-nextbutton-content {
  display: inline;
  font-size: 13px;
}
.jrs-modal-nextbutton.jrs-link .jrs-modal-nextbutton-content:hover {
  filter: brightness(110%);
  cursor: pointer;
}
.jrs-modal-nextbutton.jrs-link .jrs-modal-nextbutton-arrow {
  display: none;
}

.jrs-modal-statusmessage {
  align-items: center;
  border-radius: 4px;
  padding: 5px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.01em;
  background-color: var(--jrs-blue);
  color: white;
}
.jrs-modal-statusmessage.jrs-green {
  background-color: #def8db;
  color: #349e2d;
}
.jrs-modal-statusmessage.jrs-red {
  background-color: #ffeded;
  color: #bb2222;
}
.jrs-modal-statusmessage.jrs-yellow {
  background-color: #f8efc4;
  color: #bbb122;
}
.jrs-modal-statusmessage.jrs-gray {
  background-color: #ebebeb;
  color: #a5a5a5;
}
.jrs-modal-statusmessage-content {
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
}
.jrs-modal-statusmessage-icon {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
  height: 26px;
  width: 26px;
  margin-right: 10px;
  background-color: rgba(0, 0, 0, 0.05);
}
.jrs-modal-statusmessage-button {
  cursor: pointer;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 26px;
  padding: 0px 24px;
  margin-left: 15px;
  margin-right: 5px;
  background-color: #349e2d;
  color: white;
  opacity: 1;
  background-color: var(--jrs-modal-text);
}
.jrs-red .jrs-modal-statusmessage-button {
  background-color: #bb2222;
  color: white;
}
.jrs-modal-statusmessage-button:hover {
  opacity: 0.8;
}

.jrs-modal-gif {
  flex-direction: row;
}
.jrs-modal-video {
  border-radius: 5px;
  justify-content: center;
}
