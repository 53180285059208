.link-copy-button {
  color: hsl(208, 100%, 53%);
  border: 1px solid hsl(208, 100%, 53%);
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  cursor: pointer;
}

.link-copy-button.icon-only {
  border: none;
  padding: 0;
}

.link-copy-button .icon {
  margin-left: 10px;
}

.link-copy-button.icon-only .icon {
  margin-left: 0;
}
