.jide-math-problem {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 14px;
  font-size: 15px;
  max-height: 100%;
  overflow-y: hidden;
}

.jide-math-problem .scrollable-content {
  overflow-y: hidden;
  flex: 100;
  position: relative;
}

.jide-math-problem .problem-instructions {
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: hsl(207, 20%, 46%);
  line-height: 1.6em;
}

.jide-math-problem .problem-instructions span.katex {
  color: hsl(208, 14%, 24%);
  font-size: 1.3em;
  line-height: 1.8em;
}

.problem-answer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
}

.problem-answer .label-and-correctness {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
}

.problem-answer .label-and-correctness .correctness-tag {
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

.problem-answer .label-and-correctness .correctness-tag .icon {
  height: 6px;
  width: 6px;
  padding: 4px;
  border-radius: 50%;
  margin-right: 6px;
}

.problem-answer .label-and-correctness .correctness-tag .icon path,
.problem-answer .label-and-correctness .correctness-tag .icon polyline {
  stroke: #fff;
}

.problem-answer .label-and-correctness .correctness-tag.correct {
  color: rgb(31, 191, 94);
}

.problem-answer .label-and-correctness .correctness-tag.correct .icon {
  background: rgb(31, 191, 94);
}

.problem-answer .label-and-correctness .correctness-tag.incorrect {
  color: hsl(0, 72%, 49%);
}

.problem-answer .label-and-correctness .correctness-tag.incorrect .icon {
  background: hsl(0, 72%, 49%);
}

.problem-answer .label-and-correctness label {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  margin-right: 8px;
  color: hsl(207, 20%, 46%);
  font-weight: bold;
}

.problem-answer .problem-changed-msg {
  margin-top: 10px;
  font-size: 12px;
  color: hsl(207, 20%, 46%);
}

.problem-answer textarea {
  padding: 10px;
  font-size: 14px;
  line-height: 1.3em;
  border: 1px solid hsl(197, 58%, 80%);
  border-radius: 2px;
  margin-top: 12px;
  background: hsl(200, 100%, 98%);
  color: hsl(207, 20%, 46%);
}

.problem-answer button {
  align-self: flex-end;
  margin-top: 12px;
}

.problem-answer button .icon {
  background: hsl(208, 100%, 62%);
}

.problem-answer button .icon path {
  fill: #fff;
}

.problem-answer.has-answer-attempt.correct textarea.show-correctness {
  color: rgb(31, 191, 94);
  border: 1px solid rgb(31, 191, 94);
  background: rgba(31, 191, 94, 0.05);
}

.problem-answer.has-answer-attempt.incorrect textarea.show-correctness {
  color: hsl(0, 72%, 49%);
  border: 1px solid hsl(0, 72%, 49%);
  background: hsla(0, 72%, 49%, 0.05);
}
