.jce {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  box-sizing: border-box;
  padding: 0px;

  --jce-bg: rgba(36, 37, 47, 1);
  --jce-bg-light: rgb(48, 51, 63);
  --jce-white: rgba(250, 250, 240, 1);
  --jce-white-faint: rgba(250, 250, 240, 0.05);
  --jce-white-light: rgba(250, 250, 240, 0.3);
}
.jce-bar {
  display: flex;
  height: 50px;
  min-height: 50px;
  width: 100%;
  justify-content: space-between;
  background-color: #0f3a52;
  color: white;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10px;
}
.jce-bar-left {
  display: flex;
  height: 100%;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.85);
}
.jce-bar-right {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  align-items: center;
}
.jce-controls {
  display: flex;
  height: 60%;
  width: 80px;
  font-family: "Gotham", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: white;
  padding: 0px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}
.jce-controls > div {
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.jce-controls.jce-status-button {
  width: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.jce-controls.jce-status-button.jce-modal {
  /* display: none; */
}
.jce-controls.jce-run {
}
.jce-controls.jce-stop {
}
.jce-controls.jce-restore {
  padding: 0 1.15em;
  width: auto;
}
.jce-controls.jce-restore.jce-inactive {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  cursor: auto;
}
.jce-controls.jce-run.jce-active {
  background-color: #3da5ff;
}
.jce-controls.jce-stop.jce-active {
  background-color: #d52224;
}
.jce-controls.jce-run.jce-inactive.jce-in-progress {
  background-color: rgba(61, 165, 255, 0.67);
  cursor: auto;
}
.jce-controls.jce-stop.jce-inactive.jce-in-progress {
  background-color: rgba(213, 34, 37, 0.67);
  cursor: auto;
}
.jce-controls.jce-run.jce-inactive:not(.jce-in-progress) {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  cursor: auto;
}
.jce-controls.jce-stop.jce-inactive:not(.jce-in-progress) {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  cursor: auto;
}

.jce-main {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-grow: 1;
  background-color: #292a36;
}
.jce-file-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.jce-file-viewer .jce-status-button {
  display: none;
  font-size: 40px;
  background-color: rgb(15, 58, 82);
  color: rgb(255, 255, 255, 0.8);
  border-radius: 7px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}
.jce-file-viewer .jce-status-button.jce-modal {
  display: block;
  position: absolute;
  z-index: 100;
  top: 15%;
  width: 120px;
  height: 120px;
}
.jce-ace {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.jce-ace .ace_gutter {
  color: rgba(248, 248, 242, 0.5);
}
.jce-ace.new-horizons {
  padding-top: 15px;
}

.jce-ace .ace-dracula .ace_marker-layer .ace_active-line {
  background: rgba(68, 71, 90, 0.3) !important;
}
.jce-ace .ace-dracula .ace_gutter-active-line {
  background: rgba(68, 71, 90, 0.3) !important;
}
.jce-file-viewer img,
.jce-file-viewer video,
.jce-file-viewer audio {
  max-width: 95%;
  max-height: 95%;
}
.jce-file-viewer.jfv-text {
  max-width: 85%;
  text-align: center;
}

.jce-status-button.jce-hardreset {
  background-color: rgba(97, 34, 213, 0.67) !important;
}
.jce-status-button > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.jce-status-button > div > svg {
  flex-grow: 1;
}
.jce-status-button-text {
  flex-grow: 0;
  margin-top: 5px;
  font-size: 8px;
  height: 30px;
  font-family: "Gotham", sans-serif;
}
.jce-status-button.jce-spinning {
  animation: colorcycle1 1s infinite;
  -moz-animation: colorcycle2 1s infinite;
  -webkit-animation: colorcycle3 1s infinite;
}
@keyframes colorcycle1 {
  0% {
    background-color: rgb(255, 255, 255);
  }
  50% {
    background-color: rgb(61, 165, 255);
  }
  100% {
    background-color: rgb(255, 255, 255);
  }
}
@-moz-keyframes colorcycle2 {
  0% {
    background-color: rgba(255, 255, 255, 1);
  }
  50% {
    background-color: rgba(61, 165, 255, 1);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}
@-webkit-keyframes colorcycle3 {
  0% {
    background-color: rgba(31, 84, 112, 0.95);
  }
  50% {
    background-color: rgba(42, 109, 167, 0.95);
  }
  100% {
    background-color: rgba(31, 84, 112, 0.95);
  }
}

.jce-si {
  display: flex;
  background-color: var(--jce-bg);
  height: 100%;
  color: var(--jce-white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}
.jce-si div {
  display: flex;
  box-sizing: border-box;
}
.jce-si a {
  text-decoration: none;
}

.jce-si-content {
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow: auto;
}
.jce-si-content-wrapper {
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: auto;
}

.jce-si-title {
  font-family: "Open Sans", sans-serif;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 0.3pt;
}
.jce-si-video {
  max-height: 350px;
  width: 100%;
  margin-bottom: 20px;
  justify-content: center;
  border-radius: 5px;
}
.jce-si-video video {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}
.jce-si-image {
  max-height: 350px;
  width: auto;
  margin-bottom: 20px;
}
.jce-si-link {
  background-color: var(--jce-white);
  border: 2px solid #0f8fff;
  border-radius: 5px;
  margin-bottom: 20px;
  align-items: center;
}
.jce-si-link-left {
  color: var(--jce-bg);
  font-size: 13px;
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
}
.jce-si-link-left:hover {
  color: #0f8fff;
}
.jce-si-link-right {
  cursor: pointer;
  background-color: #0f8fff;
  padding: 8px;
  margin-right: 6px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  color: var(--jce-white);
  opacity: 0.8;
}
.jce-si-link-right:hover {
  opacity: 1;
}
.jce-si-link-right-left {
  font-family: "Gotham", sans-serif;
  font-size: 11px;
  margin-right: 5px;
  letter-spacing: 0.3pt;
  justify-content: center;
}
.jce-si-link-right-right {
  justify-content: center;
  width: 14px;
  font-size: 12px;
}
.jce-si-text {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  padding: 20px;
  margin-bottom: 10px;
}

.jce-si-nav {
  background-color: var(--jce-bg-light);
  color: var(--jce-white-light);
  position: relative;
  z-index: 3;
  bottom: 30px;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
}
.jce-si-nav-arrow {
  cursor: pointer;
  font-size: 22px;
  justify-content: center;
  align-items: center;
  padding: 6px 15px;
  border-right: 2px solid var(--jce-bg);
}
.jce-si-nav-arrow:last-child {
  border-right: 0px;
  border-left: 2px solid var(--jce-bg);
}
.jce-si-nav-arrow:hover {
  color: var(--jce-white);
  background-color: var(--jce-white-light);
}
.jce-si-nav-dot {
  cursor: pointer;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.jce-si-nav-dot.jce-si-active {
  color: var(--jce-white);
}
.jce-si-nav-dot:not(.jce-si-active):hover {
  color: var(--jce-white);
  background-color: var(--jce-white-light);
  opacity: 0.5;
}
.jce-si-nav-dot.jce-si-active:hover {
  color: var(--jce-white);
  background-color: var(--jce-white-light);
}
