.ui-page-error {
  padding: 2rem 1rem;
  margin: 0 auto;
  font-size: 1rem;
  width: 50%;
}

.ui-page-error .ui-content {
  font-size: 1.2em;
}
