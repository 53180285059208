.learnerlogin tbody {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.learnerlogin tbody tr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  align-items: center;
  padding: 0;
  border: 1px solid #dae1e6;
}

.learnerlogin tbody > :not(:first-child) {
  border-top: 0px;
}
.learnerlogin tbody tr td {
  padding: 10px 10px;
}

.learnerlogin tbody tr th {
  max-width: 40%;
  width: 40%;
  font-weight: normal;
}
.learnerlogin tbody tr td {
  max-width: 60%;
  width: 60%;
  word-wrap: break-word;
}
.learnerlogin .nav-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 170px;
  margin: 1.5rem 0 0 0rem;
  margin-top: 8px;
  padding: 0 24px;
}

/* copied from the now-removed src/payments/PaymentNav/payment_nav.css */
.nav {
  font-family: 'Gotham';
  background-color: white;
  color: hsl(208, 100%, 53%);
  box-shadow: 0px 10px 20px -10px #d9ecf7;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 1000;
  user-select: none;
}

/* copied from the now-removed src/payments/PaymentNav/payment_nav.css */
.nav-logo > a {
  display: flex;
  align-items: center;
}

.auth-form-signup .invitation-used .btn-group {
  display: flex;
  flex-direction: column;
}
.auth-form-signup .invitation-used .btn-group a {
  flex: 1 0 auto;
}
.auth-form-signup .invitation-used .btn-group button {
  width: 100%;
}
@media (min-width: 768px) {
  .learnerlogin .nav-logo {
    margin: 2rem 0 0 1rem;
  }
  .auth-form-signup .invitation-used .btn-group {
    flex-direction: row;
  }
}
@media (max-width: 768px) {
  .auth-form-signup .invitation-used .btn-group > *:not(:first-child),
  .auth-form-signup .invitation-used > h1,
  .auth-form-signup .invitation-valid > h1 {
    margin-top: 1rem;
  }
  .forgot-password-view .btn-group button,
  .auth-form-signup .invitation-valid .btn-group button {
    width: 100%;
  }
}
.learnerlogin tbody tr input {
  width: 100%;
}
.auth-form-signup .form-error-wrap {
  margin: 0 !important;
}
/* .learnerlogin tbody>:nth-of-type(odd) {
    background: #eefcff
}

.learnerlogin tbody>:nth-of-type(even) {
    background: #dbf8ff
} */

.learnerlogin .underline {
  text-decoration: underline;
  font-size: 16px;
}

.learnerlogin .purchase-step .optional {
  margin: -0.5rem 0 0.5rem;
}

.learnerlogin video {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
}

.learnerlogin video.contain {
  object-fit: contain;
}

.learnerlogin #drift-widget-container {
  z-index: 2147483646 !important;
}

.learnerlogin ::selection {
  background: #2d0f74;
  color: white;
}

.learnerlogin .flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.learnerlogin .flex > * {
  -webkit-box-flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.learnerlogin .flex-equal > * {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.learnerlogin .align-items-stretch {
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}

.learnerlogin .align-items-center {
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.learnerlogin .align-self-start {
  -ms-flex-self: start;
  -webkit-box-self: start;
  -webkit-align-self: start;
  align-self: start;
}

.learnerlogin .align-items-start {
  -ms-flex-align: flex-start;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.learnerlogin .align-items-end {
  -ms-flex-align: flex-end;
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.learnerlogin .justify-content-start {
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
}

.learnerlogin .justify-content-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.learnerlogin .justify-content-end {
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}

.learnerlogin .justify-space-between {
  -webkit-box-pack: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
}

.learnerlogin .justify-space-around {
  -webkit-box-pack: space-around;
  -webkit-justify-content: space-around;
  -ms-flex-pack: space-around;
  justify-content: space-around;
}

.learnerlogin .flex-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.learnerlogin .flex-column > * {
  -webkit-box-flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.learnerlogin .flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.learnerlogin .invert {
  color: white;
}

.learnerlogin .invert * {
  color: white;
}

.learnerlogin .invert .subtitle {
  opacity: 0.7;
}

.learnerlogin .invert .subtle {
  opacity: 0.5;
}

.learnerlogin .invert ::placeholder {
  color: white;
}

.learnerlogin hr {
  height: 1px;
  width: 100%;
  border: 0;
  border-top: 1px solid #dee5e9;
  margin: 2rem 0;
}

.learnerlogin ul {
  padding: 0;
  margin: 0;
}

.learnerlogin ul li.active {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #00a5ff;
  font-weight: 800;
}

.learnerlogin ul li.active * {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #00a5ff;
  font-weight: 800;
}

.learnerlogin ul.flex > :not(:first-child) {
  margin-left: 10px;
}

.learnerlogin ul:not(.list) li {
  list-style: none;
}

.learnerlogin ul.padded {
  padding: 0 0 0 20px;
}

.learnerlogin ul.padded > * {
  margin-top: 2rem;
}

.learnerlogin ul.invert li {
  opacity: 0.5;
  color: white;
}

.learnerlogin ul.invert li.active {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #00a5ff;
  font-weight: 800;
  color: white;
  opacity: 1;
}

.learnerlogin ul.invert li.active * {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #00a5ff;
  font-weight: 800;
  color: white;
  opacity: 1;
}

.learnerlogin .nocollapse {
  white-space: nowrap;
}

.learnerlogin .mt1 {
  margin-top: 1rem;
}

.learnerlogin .mt2 {
  margin-top: 2rem;
}

.learnerlogin .mr1 {
  margin-right: 1rem !important;
}

.learnerlogin .ml1 {
  margin-left: 10px;
}

.learnerlogin .ml2 {
  margin-left: 2rem !important;
}

.learnerlogin .mr2 {
  margin-right: 2rem !important;
}

.learnerlogin .mb1 {
  margin-bottom: 1rem !important;
}

.learnerlogin .mb2 {
  margin-bottom: 2rem !important;
}

.learnerlogin figure {
  margin: 0;
}

.learnerlogin strong,
.learnerlogin .strong {
  font-weight: 800;
}

.learnerlogin hgroup {
  padding: 2rem 0;
}

.learnerlogin hgroup h1 {
  margin-bottom: 2rem;
}

.learnerlogin hgroup small.subtle {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.learnerlogin hgroup h2 {
  margin: 0;
}

.learnerlogin hgroup h6 {
  margin: 0.5rem 0 2rem 0;
  font-weight: 300;
  line-height: 28px;
}

.learnerlogin * {
  font-family: 'Graphik', 'Open Sans', sans-serif;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  box-sizing: border-box;
}

.learnerlogin .bodytxt {
  font-family: 'Nunito Sans', 'Open Sans', sans-serif;
}

.learnerlogin .bodytxt * {
  font-family: 'Nunito Sans', 'Open Sans', sans-serif;
}

.learnerlogin html,
.learnerlogin body {
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.learnerlogin body {
  margin: 0px;
}

.learnerlogin body main {
  min-height: 95vh;
}

.learnerlogin button,
.learnerlogin input,
.learnerlogin optgroup,
.learnerlogin select,
.learnerlogin textarea {
  font-family: 'Nunito Sans', 'Open Sans', sans-serif;
}

.learnerlogin input {
  font-size: 12px;
}

.learnerlogin h1,
.learnerlogin h2,
.learnerlogin h3,
.learnerlogin h4,
.learnerlogin h5,
.learnerlogin h6 {
  font-family: 'Open sans', sans-serif !important;
}

.learnerlogin .flex.align-items-center > h1,
.learnerlogin .flex.align-items-center > h2,
.learnerlogin .flex.align-items-center > h3,
.learnerlogin .flex.align-items-center > h4,
.learnerlogin .flex.align-items-center > h5,
.learnerlogin .flex.align-items-center > h6 {
  margin: 0;
}

.learnerlogin nav {
  position: absolute;
  z-index: 123;
  height: 60px;
  margin: 0 2rem;
  width: calc(100% - 4rem);
  border-bottom: 1px solid #56e6d0;
}

.learnerlogin nav .nav-inner {
  width: 100%;
}

.learnerlogin nav .logo-wrap {
  text-decoration: none;
  width: 100%;
}

.learnerlogin nav .logo-wrap a {
  text-decoration: none;
}

.learnerlogin nav .logo-wrap a .logo {
  margin-left: 15px;
  color: #56e6d0;
  font-size: 20px;
}

.learnerlogin img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.learnerlogin img.fullheight {
  height: 100%;
}

.learnerlogin h1 {
  margin: 0;
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  .learnerlogin h1 {
    margin: 0;
    font-size: 2rem;
  }
}

.learnerlogin h1.xl {
  font-size: 3rem;
}

.learnerlogin h2 {
  font-weight: 800;
  font-size: 2rem;
}

.learnerlogin h3 {
  font-size: 2rem;
}

.learnerlogin h4 {
  font-size: 1.66rem;
}

.learnerlogin h5 {
  font-size: 1.33rem;
}

.learnerlogin h6 {
  font-size: 1rem;
}

.learnerlogin a {
  color: #000e15;
  font-weight: 800;
  text-decoration: none;
}

.learnerlogin a.tos {
  text-decoration: underline;
}

.learnerlogin a.tos:hover {
  text-decoration: none;
}

.learnerlogin p {
  font-weight: 300;
}

.learnerlogin p.para {
  color: rgba(26, 158, 230, 0.5);
}

.learnerlogin p.secondary {
  color: #8585bd;
  font-weight: 300;
}

.learnerlogin p.desc {
  font-size: 18px;
}

.learnerlogin small {
  display: block;
  font-size: 12px;
  line-height: 20px;
}

.learnerlogin small.para {
  color: rgba(26, 158, 230, 0.5);
}

.learnerlogin .container {
  margin: 0 auto;
  max-width: 500px;
  padding: 3rem 1rem;
}

.learnerlogin .container.thin {
  max-width: 768px;
  margin: 0 auto;
}

.learnerlogin .container.wide {
  max-width: 1440px;
}

.learnerlogin .container-sm {
  padding: 0 1rem;
}

.learnerlogin .row {
  margin: 1rem 0;
}

.learnerlogin .spacey {
  letter-spacing: 1px;
}

.learnerlogin .subtitle {
  text-transform: uppercase;
  color: #00a5ff;
}

.learnerlogin .subtle {
  margin: 0;
  color: #4f5c6875;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 800;
}

.learnerlogin .headline {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 1rem;
}

.learnerlogin .headline p {
  font-weight: 800;
  font-size: 24px;
  opacity: 0.1;
}

.learnerlogin .headline .headline-line {
  margin-left: 10px;
  width: 100%;
  height: 1px;
  height: 10px;
  background: rgba(248, 248, 253, 0.1) !important;
}

.learnerlogin .text-left {
  text-align: left;
}

.learnerlogin .text-center {
  text-align: center;
}

.learnerlogin .text-right {
  text-align: right !important;
}

@-webkit-keyframes rotateAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    fill: #0071ae;
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotateAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    fill: #0071ae;
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes rotateAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    fill: #0071ae;
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotateAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    fill: #0071ae;
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.learnerlogin .rotate {
  -webkit-animation: rotateAnimation 2s infinite linear;
  animation: rotateAnimation 2s infinite linear;
  display: inline-block;
  color: #87e2d5;
  font-size: 64px;
}

.learnerlogin .ghost-wrap {
  position: relative;
  z-index: 1;
}

.learnerlogin .ghost-wrap .ghost {
  position: absolute;
  z-index: 0;
  font-weight: 800;
  top: calc(50% - 50px);
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  opacity: 0.0375;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .learnerlogin .margined {
    margin: 1rem 0;
  }

  .learnerlogin .margined > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  .learnerlogin button.micro {
    height: 34px;
    font-size: 12px;
    padding: 5px 10px;
  }

  .learnerlogin .invert-sm {
    color: white;
  }

  .learnerlogin .invert-sm * {
    color: white;
  }

  .learnerlogin .hide-sm {
    display: none;
  }

  .learnerlogin .minimize-thin-sm {
    font-size: 12px;
  }

  .learnerlogin .flex-column-reverse-sm {
    -ms-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .learnerlogin .flex-column-sm {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .learnerlogin .flex-row-sm {
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }

  .learnerlogin .flex-row-sm > * {
    -webkit-box-flex: 0 1 auto;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
}

@media (min-width: 768px) {
  .learnerlogin .margined {
    margin: 0.5rem 0;
  }

  .learnerlogin .margined > *:not(:first-child) {
    margin-left: 0.5rem;
  }

  .learnerlogin .margined > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  .learnerlogin h2 {
    font-size: 3rem;
  }

  .learnerlogin .constrain-lg {
    max-width: 410px;
  }

  .learnerlogin .hide-lg {
    display: none;
  }

  .learnerlogin .justify-content-center-lg {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .learnerlogin .justify-space-between-lg {
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
  }

  .learnerlogin .align-items-center-lg {
    -ms-flex-align: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .learnerlogin .container {
    padding: 0 2rem;
    max-width: 1168px;
  }

  .learnerlogin .text-center-lg {
    text-align: center;
  }

  .learnerlogin .text-right-lg {
    text-align: right;
  }

  .learnerlogin main nav {
    position: absolute;
    top: 0;
  }
}

@media (min-width: 768px) {
  .learnerlogin .btn-group {
    margin-top: 2rem;
  }

  .learnerlogin .btn-group > *:not(:first-child) {
    margin-left: 15px;
  }
}

.learnerlogin select {
  z-index: 100;
  height: 38px;
  border: 1px solid #dee5e9;
  background: white;
  border-radius: 0px;
  color: #0071ae;
  font-family: 'Nunito Sans', 'Open Sans', sans-serif;
}

.learnerlogin input,
.learnerlogin textarea {
  z-index: 123;
  border: 1px solid #dee5e9;
  background: white;
  font-family: 'Nunito Sans', 'Open Sans', sans-serif;
  font-size: 12px;
  padding: 10px;
  height: initial;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px solid #e8e8f1;
  background: white;
  z-index: 1;
  padding: 1rem;
  color: #320e78;
}

.learnerlogin input::placeholder,
.learnerlogin textarea::placeholder {
  color: #648194;
  font-size: 14px;
  opacity: 0.5;
  font-style: italic;
}

.learnerlogin form.ff-form {
  padding: 2rem;
}

.learnerlogin form.ff-form small.subtle {
  margin: 0 0 0.75rem 0.25rem;
}

.learnerlogin form.ff-form .form-group > * {
  width: 100%;
  margin-bottom: 1rem;
}

.learnerlogin form.inline {
  margin-top: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.learnerlogin form.inline > * {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.learnerlogin form.inline button {
  -webkit-box-flex: 0 1;
  -webkit-flex: 0 1;
  -ms-flex: 0 1;
  flex: 0 1;
  border: 0px;
  border-radius: 0px;
}

.learnerlogin form.inline input {
  background: rgba(255, 255, 255, 0.0392157);
  border: 2px solid #4a149c;
  border-right: 0;
  padding: 0 1rem;
}

.learnerlogin form.inline input::placeholder {
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  .learnerlogin .form-inline > *:not(:last-child) {
    margin-right: 1rem;
  }
}

.instructorlogin .nav {
  position: fixed;
  background: initial;
  box-shadow: initial;
}

.instructorlogin .nav .nav-right {
  display: none;
}

.instructorlogin .nav .nav-left img {
  width: 120px;
  filter: invert(100%);
  margin-left: 1rem;
}
.forgot_password .nav {
  position: fixed;
  background: initial;
  box-shadow: initial;
}

.forgot_password .nav .nav-right {
  display: none;
}

.forgot_password .nav .nav-left img {
  width: 120px;
  filter: invert(100%);
  margin-left: 1rem;
}

.learnerlogin .nav {
  position: fixed;
  background: initial;
  box-shadow: initial;
}

.learnerlogin .nav .nav-right {
  display: none;
}

.learnerlogin .nav .nav-left img {
  width: 120px;
  filter: invert(100%);
  margin-left: 1rem;
}

.learnerlogin #auth {
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  flex-direction: column;
}

.learnerlogin #auth > #auth-content-wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  z-index: 10;
}

.learnerlogin #auth #auth-sidebar-wrap {
  padding: 0 2rem;
  position: sticky;
  top: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  height: 70px;
}

@media only screen and (min-width: 768px) {
  #auth-sidebar-wrap {
    width: 35%;
    position: relative;
  }
}
@media only screen and (max-width: 768px) {
  .stars {
    display: none;
  }
}

.learnerlogin #auth #auth-sidebar-wrap * {
  color: white;
}

.learnerlogin #auth #auth-sidebar-wrap .subtitle {
  opacity: 0.7;
}

.learnerlogin #auth #auth-sidebar-wrap .subtle {
  opacity: 0.5;
}

.learnerlogin #auth #auth-sidebar-wrap ::placeholder {
  color: white;
}

.learnerlogin #auth #auth-sidebar-wrap .stars svg {
  width: 100px;
  height: 100px;
  position: absolute;
  transform: scale(5);
  left: calc(100% - 3rem);
}

.learnerlogin #auth #auth-sidebar-wrap .stars #triangle {
  left: calc(100% - -1rem);
  top: 34rem;
}

.learnerlogin #auth #auth-sidebar-wrap .stars #star {
  transform: scale(7);
  left: 0;
  top: 0;
}

.learnerlogin #auth #auth-sidebar-wrap #auth-sidebar {
  max-width: 350px;
  text-align: center;
  display: none;
}

.learnerlogin #auth #auth-content-wrap {
  background: white;
  padding: 0 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding-bottom: 24px;
}
.learnerlogin #auth #auth-content-wrap::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

@media (min-width: 768px) {
  .learnerlogin #auth #auth-content-wrap {
    padding: 0 3rem;
    padding-bottom: 24px;
  }
}

.learnerlogin #auth #auth-content-wrap #auth-content .auth-form {
  width: 100%;
  max-width: 500px;
}

.learnerlogin #auth #auth-content-wrap #auth-content hr {
  margin: 2rem 0 1rem;
}

.learnerlogin #auth #auth-content-wrap #auth-content form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.learnerlogin #auth #auth-content-wrap #auth-content form > *:not(table) {
  margin-top: 1.5rem;
}

.learnerlogin #auth #auth-content-wrap #auth-content form table {
  width: 100%;
  border-collapse: collapse;
}

.learnerlogin #auth #auth-content-wrap #auth-content form > .form-error-wrap {
  margin-bottom: -1.5rem;
}

.learnerlogin #auth #auth-content-wrap #auth-content form .form-field {
  position: relative;
  width: 100%;
  height: 60px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  border-radius: 3px;
  overflow: hidden;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  form
  .form-field.error
  .form-field-icon {
  background: #ff3333;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  form
  .form-field.error
  .form-field-icon
  [class*='icon-'] {
  color: white;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  form
  .form-field.error
  input {
  background: #ffe4e4;
  border: 2px solid #ff3333;
  font-weight: 800;
  color: #e01a1a;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  form
  .form-field
  .form-field-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  background: #e8f1f7;
  padding: 0 1rem;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  form
  .form-field
  [class*='icon-'] {
  color: #a9b6bd;
}

.learnerlogin #auth #auth-content-wrap #auth-content form .form-field input {
  border: 0;
  width: 100%;
  background: #f0f7fb;
}

.learnerlogin #auth #auth-content-wrap #auth-content form .btn-group {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
}

.learnerlogin #auth #auth-content-wrap #auth-content #account-types {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  max-height: 100vh;
}

@media only screen and (max-width: 768px) {
  .learnerlogin #auth #auth-content-wrap #auth-content #account-types {
    max-height: unset;
  }
}

.learnerlogin #auth #auth-content-wrap #auth-content #account-types > * {
  margin-top: 2rem;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  #account-types
  .account-type {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  box-shadow: 0px 20px 130px -10px #e5eef3;
  border: 1px solid #f0f5f7;
  border-radius: 5px;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  #account-types
  .account-type
  .account-type-head {
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding: 1rem;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  #account-types
  .account-type
  .account-type-head
  h5 {
  margin: 1rem 0;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  #account-types
  .account-type
  .account-type-head
  .account-type-img-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  #account-types
  .account-type
  .account-type-head
  .account-type-img-wrap
  > * {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  object-fit: contain;
  height: 150px;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  #account-types
  .account-type
  button {
  border-top: 1px solid #f0f5f7;
}

@media (min-width: 768px) {
  .learnerlogin #auth {
    min-height: 100vh;
    flex-direction: row;
  }
  .learnerlogin #auth #auth-sidebar-wrap {
    height: initial;
  }
  .learnerlogin #auth #auth-sidebar-wrap #auth-sidebar {
    display: block;
  }
}
