.ui-field {
  display: block;
}

.ui-field > * + * {
  margin-top: 0.5rem;
}

.ui-field-label {
  font-weight: bold;
}

.ui-field-error {
  font-size: 0.9em;
  color: #c13a53;
}

.ui-input {
  background: #fbfdff;
  border: 1px solid hsl(201, 70%, 50%);
  border-radius: 0.5rem;
  box-shadow: inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
  font-family: inherit;
  font-size: 1rem;
  line-height: 1;
  box-sizing: border-box;
  padding: 1rem;
  height: 3rem;
  width: 100%;
}

.ui-input:focus {
  border: 2px solid #00a5ff;
  padding-left: calc(1rem - 1px);
  box-shadow: 0px 0.25rem 1rem rgba(0, 165, 255, 0.2),
    inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
}

.ui-input:disabled {
  background: #e7eaec;
  border: 2px solid #adc2ce;
  color: #b7c0c7;
}

.ui-input.ui-input-error {
  background: #ffffff;
  border-color: #c13a53;
  color: #a73434;
}

.ui-input::placeholder {
  color: #adc2ce;
}

.ui-input.ui-input-error::placeholder {
  color: #ee808c;
}

.ui-button-row {
  margin: 0rem -0.5rem -0.5rem -0.5rem;
}

.ui-button-row * {
  margin: 0.5rem;
}

.ui-button-row.centered {
  text-align: center;
}

.ui-pre-wrap {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

/* UI Content styles reflect style guide */

/**
 * Fonts
 */

.ui-content {
  font-family: "Graphik", "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #292563;
  line-height: 1.5;
}

.ui-content h1,
.ui-content h2,
.ui-content h3,
.ui-content h4,
.ui-content p,
.ui-admin-content h1,
.ui-admin-content h2,
.ui-admin-content h3,
.ui-admin-content h4,
.ui-admin-content p,
.learner-app .ui-content p {
  margin: 0;
}

.ui-content h1,
.ui-admin-content h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
}

.ui-content h2,
.ui-admin-content h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

.ui-content h3,
.ui-admin-content h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.ui-content h4,
.ui-admin-content h4 {
  font-weight: 500;
  color: #a4b2fd;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.ui-content .ui-large-text {
  font-size: 1.5em;
}

.ui-content .ui-small-text {
  font-size: 0.75em;
  color: #5f798c;
}

.ui-content a {
  color: #4865fc;
}

.ui-admin-content {
  font-family: "Graphik", "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #292563;
  letter-spacing: 0.02em;
  line-height: 1.5;
}

/**
 * Layout
 */

.ui-row {
  display: flex;
  flex-direction: row;
}

/**
 * Cards
 */

.ui-card-base {
  box-shadow: 0px 0.6rem 1rem rgba(209, 231, 239, 0.4);
  border-radius: 0.25rem;
}

/**
 * Spaced Content
 */
.ui-vertical-spacing > * + *,
.ui-content .ui-vertical-spacing > * + *,
.ui-content.ui-vertical-spacing > * + *,
.ui-admin-content .ui-vertical-spacing > * + *,
.learner-app .ui-content .ui-vertical-spacing > * + * {
  margin-top: 1rem;
}
.ui-vertical-spacing-small > * + *,
.ui-content .ui-vertical-spacing-small > * + *,
.ui-admin-content .ui-vertical-spacing-small > * + *,
.learner-app .ui-content .ui-vertical-spacing-small > * + * {
  margin-top: 0.5rem;
}

.ui-horizontal-spacing > * + *,
.ui-content .ui-horizontal-spacing > * + *,
.ui-admin-content .ui-horizontal-spacing > * + *,
.learner-app .ui-content .ui-horizontal-spacing > * + * {
  margin-left: 1rem;
}

.ui-content .ui-no-top-margin,
.ui-admin-content .ui-no-top-margin,
.learner-app .ui-content .ui-no-top-margin {
  margin-top: 0;
}

.ui-grid {
  margin: -0.5rem;
}

.ui-grid * {
  margin: 0.5rem;
}

.ui-grid-small {
  margin: -0.25rem;
}

.ui-grid-small * {
  margin: 0.25rem;
}
