.jide {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: transparent;
}

.jide .spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
