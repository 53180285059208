.ring-spinner {
  display: inline-block;
  position: relative;
}
.ring-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: ring-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.ring-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.ring-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.ring-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes ring-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
