.cuteprogressbar {
  height: 30px;
  border-radius: 15px;
  border: 2px solid #75cefd;
  background-color: #eaf6fb;
  overflow: hidden;
  padding: 2px;
  box-shadow: 0px 0px 6px 0px rgba(117, 206, 253, 0.5) inset;
}
.cuteprogressbar > span {
  display: block;
  height: 100%;
  border-radius: 11px;
  background-color: rgba(0, 166, 255, 1);
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 11px 0px rgba(255, 255, 255, 0.4) inset;
}
.cuteprogressbar > span:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: barbershop 2s linear infinite;
  overflow: hidden;
}

@keyframes barbershop {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
